import React, { useEffect, useCallback, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { fetchLockedJobs, createLockedJob, toggleLockedJobs } from 'features/lockedJobs/lockedJobsSlice';
import DatePicker from 'tailwind-datepicker-react';
import HeaderTitleBar from 'components/HeaderTitleBar';

const LockJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { data: lockedData, loading, error } = useSelector((state) => state.lockedJobs);

  const fetchLockedJobsData = useCallback(() => {
    dispatch(fetchLockedJobs({ page: 1, per: 9999, navigate }));
  }, [dispatch, navigate]);

  useEffect(() => {
    fetchLockedJobsData(navigate);
  }, [fetchLockedJobsData, navigate]);

  const handleClose = (state) => {
    setShow(state);
	}

  const handleChange = (date) => {
    setSelectedDate(date.toLocaleDateString('en-ca'));
  }

  const handleSave = () => {
    dispatch(createLockedJob({ lockedJobData: { start_date: selectedDate }}))
  }

  const handleToggle = (lockedJobId) => {
    dispatch(toggleLockedJobs({ lockedJobId }))
  }

  const toggleIco = (row) => {

    return (
      <>
        {!row.locked ? (
          <svg className="h-6 w-6 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
            <path d="M7 11V7a5 5 0 0 1 9.9-1" />
          </svg>
        ) : (
          <svg className="h-6 w-6 text-blue-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
            <path d="M7 11V7a5 5 0 0 1 10 0v4" />
          </svg>
        )}
      </>
    )
  }

  const options = {
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    defaultDate: new Date(),
    weekDays: ["M", "T", "W", "T", "F", "S", "S"],
    theme: { input: 'rounded-sm' },
    datepickerClassNames: 'mt-40'
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Locked Jobs'
          showBtn={false}
        />
        <div className="w-80 mt-6">
          <div className="flex items-center">
            <DatePicker
              options={options}
              setShow={handleClose}
              onChange={handleChange}
              show={show}
            />
            <button
              onClick={handleSave}
              className="flex w-40 h-8 items-center text-center text-xs bg-blue-500 text-white px-2 py-1 pl-4 rounded hover:bg-blue-600 ml-2"
            >

              <svg className="h-3 w-3 text-white"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                >
                  <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
                  <path d="M7 11V7a5 5 0 0 1 9.9-1" />
            </svg>

            <span className="ml-1 text-center">Set Lock</span>
            </button>
          </div>
          {error && (
              <span className="text-xs text-red-500">Selected week is already locked</span>
            )}
        </div>
      </div>

      <div className="p-4 bg-white shadow rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                Locked Start Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                Locked End Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                Locked/Unlocked
              </th>
            </tr>
          </thead>
          <tbody>
          {loading ? (
              <tr>
                <td colSpan={3} className="text-center p-4">
                  Loading...
                </td>
              </tr>
          ) : (
            lockedData && lockedData.length > 0 ? (
              lockedData.map(row => (
                <tr key={row.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.start_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.end_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    <button
                      onClick={() => handleToggle(row.id)}
                      title={(row.locked) ? 'Unlock' : 'Lock'}
                      className=" text-right flex items-center text-center text-xs bg-white text-white"
                    >
                      {toggleIco(row)}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-left text-sm p-4">No Locked Jobs available</td>
              </tr>
            )
          )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LockJobs;