import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addJobRouteNote, editJobRouteNote } from '../../../../features/jobRoutes/jobRouteNotesSlice';

const JobDetailsNoteModal = ({ isOpen, onClose, existingNote, jobRouteId, companyId, noteType, notesLoading }) => {
  const dispatch = useDispatch();
  const [noteContent, setNoteContent] = useState('');
  const [loading, setLoading] = useState(notesLoading);

  useEffect(() => {
    if (existingNote) {
      setNoteContent(existingNote.note);
    } else {
      setNoteContent('');
    }
  }, [existingNote]);

  const handleSave = async () => {
    setLoading(true);

    try {
      if (existingNote && existingNote.id) {
        await dispatch(editJobRouteNote({ jobRouteId, companyId, noteId: existingNote.id, noteContent })).unwrap();
      } else {
        await dispatch(addJobRouteNote({ jobRouteId, companyId, noteType, noteContent })).unwrap();
        setNoteContent(null);
      }
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">
          {existingNote ? 'Edit Note' : `Add ${noteType.charAt(0).toUpperCase() + noteType.slice(1)} Note`}
        </h2>
        <textarea
          className="w-full p-2 border border-gray-300 rounded"
          rows="4"
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          placeholder="Enter note here..."
        />
        <div className="flex justify-end mt-4">
          <button onClick={onClose} className="mr-2 text-xs bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded">Cancel</button>
          <button
            onClick={handleSave}
            className="bg-transparent text-xs hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            disabled={loading}
          >
            {loading ? 'Saving...' : existingNote ? 'Save Changes' : 'Add Note'}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default JobDetailsNoteModal;