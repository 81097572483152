import React from 'react';
import { useNavigate } from 'react-router-dom';

import 'tailwindcss/tailwind.css';

const BillJobsBtn = ({ btnTheme }) => {
  const { btnClassName, icoClassName } = btnTheme;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/billing/bill_jobs");
  };

  return (
    <button onClick={handleClick} className={`${btnClassName}`}>
      <svg className={`${icoClassName}`} width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="3" width="16" height="18" rx="2" />  <rect x="8" y="7" width="8" height="3" rx="1" />  <line x1="8" y1="14" x2="8" y2="14.01" />  <line x1="12" y1="14" x2="12" y2="14.01" />  <line x1="16" y1="14" x2="16" y2="14.01" />  <line x1="8" y1="17" x2="8" y2="17.01" />  <line x1="12" y1="17" x2="12" y2="17.01" />  <line x1="16" y1="17" x2="16" y2="17.01" /></svg>
      <span className="ml-2">Import Jobs from MSO</span>
    </button>
  );
};

export default BillJobsBtn;