import { clearAuthToken } from '../features/auth/authSlice';
import { clearAuthUser } from '../features/auth/authUserSlice';

const authMiddleware = (store) => (next) => (action) => {
  if (action.type && action.type.endsWith('rejected')) {
    const { error } = action;

    if (error) {
      const status = error.status || error.response?.status;
      const message = error.message || error.response?.data?.message;
      console.log('Error in action:', action)
      console.table(error)
      console.log(status, message);

      if (status === 401 || message === 'Unauthorized') {
        store.dispatch(clearAuthUser());
        store.dispatch(clearAuthToken());

        window.location.href = '/login';
      }
    }
  }

  return next(action);
};

export default authMiddleware;