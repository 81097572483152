import React, { Component } from "react"
import { LabelHElement, LabelVElement, ErrorBox } from './FormShared';

class FormTextArea extends Component {
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  InputElement = () => {
    const {
      typeOverride,
      name,
      value,
      keyName,
      onChange,
      placeholder,
      required,
      autoComplete,
      isSearchable,
      disabled,
      cols,
      rows
    } = this.props;

    return (
      <textarea
        type={typeOverride || "text"}
        className="mt-1 block w-full p-2 border border-gray-300 rounded-sm shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        id={name}
        name={name}
        defaultValue={value}
        key={keyName}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        disabled={disabled}
        rows={rows || 1}
        cols={cols}
      >
      </textarea>
    )
  }

  render() {
    const {
      horizontal,
      formErrors,
      name,
      label
    } = this.props;

    return (
      <>
      {this.props.horizontal ? (
        <div className="md:flex md:items-center mb-6">
          <LabelHElement name={name} label={label}/>
          <div className="md:w-2/3">
            {this.InputElement()}
          </div>
          <ErrorBox name={name} formErrors={formErrors} />
        </div>
      ) : (
        <div>
          <LabelVElement name={name} label={label}/>
          {this.InputElement()}
          <ErrorBox name={name} formErrors={formErrors} />
        </div>
      )}
    </>
    )
  }
}

export default FormTextArea
