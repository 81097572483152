import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import TosForm from 'components/formHelpers/TosForm';
import FormTextInput from 'components/formHelpers/FormTextInput';
import FormSubmissionResult from 'components/formHelpers/FormSubmissionResult';
import FormSelectBox from 'components/formHelpers/FormSelectBox';
import CompanySelectBox from 'components/dropdowns/CompanySelectBox';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import { editTechnician, fetchTechnician, setTechnicianSaved  } from 'features/technician/techniciansSlice';
import {
  setSelectedAreaId,
  fetchUserListByCompany
} from 'features/users/usersSlice';

import 'tailwindcss/tailwind.css';

const EditTechnician = () => {
  const { techId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    success,
    error,
    techResource,
    technicianSaved,
    loading
  } = useSelector((state) => state.technicians);

  const {
    userList,
    selectedAreaId
  } = useSelector((state) => state.users);

  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    user_id: 0,
    company_id: null,
    area_id: 0,
    tech_id: '',
    pay_rate: ''
  });

  useEffect(() => {
    dispatch(fetchTechnician(techId));
  }, [dispatch, techId]);

  useEffect(() => {
    if (technicianSaved === false) return;
    dispatch(setTechnicianSaved(false));
    navigate(`/users/${techResource.user_id}`)
  }, [navigate, dispatch, technicianSaved, techResource])

  useEffect(() => {
    if (techResource) {
      setFormData({
        company_id: techResource.company_id || null,
        area_id: techResource.area_id || 0,
        user_id: techResource.user_id,
        tech_id: techResource.tech_id || '',
        pay_rate: techResource.pay_rate || ''
      });
      setSelectedCompanyId(techResource.company_id);
      setSelectedAreaId(techResource.area_id);
      setSelectedUserId(techResource.user_id);
    }

  }, [techResource]);

  const validateForm = () => {
    let newErrors = {};

    if (!formData.user_id) {
      newErrors.user_id = 'A User is required';
    }

    if (!formData.tech_id) {
      newErrors.tech_id = 'A Tech ID is required';
    }

    setFormErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const fetchUsersData = useCallback(() => {
    const companyId = selectedCompanyId;

    if (typeof(companyId) === 'undefined' || parseInt(companyId) === 0) {
      return;
    }

    dispatch(fetchUserListByCompany({
      companyId: companyId,
      areaId: selectedAreaId,
      navigate }));
  }, [dispatch,
    selectedCompanyId,
    navigate]);

  useEffect(() => {
    fetchUsersData();
  }, [fetchUsersData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch(name) {
      case 'company_id':
        if (value === '0') {
          setSelectedCompanyId(null);
        } else {
          setSelectedCompanyId(value);
        }
        break;
      case 'area_id':
        dispatch(setSelectedAreaId(value));
        break;
      case 'user_id':
        setSelectedUserId(value);
        break;
      default:
        break;
    }

    if (name === 'company_id' || name === 'user_id') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      const newData = formData;
      newData[name] = value;
      setFormData(newData);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors({});

    if (validateForm()) {
      dispatch(editTechnician({techId, formData}))
    }
  };

  const handleBack = () => {
    navigate(`/users/${techResource.user_id}`);
  };

  return (

    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      {loading ? (
        <p className='text-sm font-medium text-gray-700 whitespace-nowrap'>Loading Technician...</p>
      ) : (
        <>
          <HeaderTitleBar
            title={`Edit Technician: ${techResource?.user_full_name}`}
            showBtn={true}
            btnTitle={'Back'}
            btnClick={handleBack}
          />

          <FormSubmissionResult
            successText={`Technician "${formData.tech_id}" for "${techResource?.user_full_name}" Updated`}
            success={success}
            error={error}
          />
          <TosForm onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">
            <CompanySelectBox
              label={'Company'}
              name={'company_id'}
              value={formData.company_id}
              onChange={handleChange}
              useTarget={false}
            />

            <AreaSelectBox
              label={'Area'}
              name={'area_id'}
              value={formData.area_id}
              onChange={handleChange}
              useTarget={false}
            />

            <FormSelectBox
              name={'user_id'}
              id={'user_id'}
              label={'User'}
              defaultValue={formData.user_id}
              valueField={'id'}
              keyField={'name'}
              zeroOption={'Select an User'}
              onChange={handleChange}
              data={userList}
            />

            <div className="grid justift-items-start grid-cols-2 gap-8">
              <FormTextInput
                label={'Tech ID'}
                name="tech_id"
                value={formData.tech_id}
                onChange={handleChange}
                placeholder="Enter a Tech ID"
                formErrors={formErrors}
              />

              <FormTextInput
                label={'Pay Rate'}
                name="pay_rate"
                value={formData.pay_rate}
                onChange={handleChange}
                placeholder="Enter a Tech ID"
                formErrors={formErrors}
              />
            </div>

            <div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Edit Technician
              </button>
            </div>
          </TosForm>
        </>
      )}
    </div>
  );
};

export default EditTechnician;
