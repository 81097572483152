import React, { useState } from 'react';
import MsoSelectBox from './dropdowns/MsoSelectBox';
import Dashboard from './Dashboard';

const MsoSelect = () => {
  //const [selectedMsoId, setSelectedMsoId] = useState(localStorage.getItem('msoId'));
  const [showDashboard, setShowDashboard] = useState(false);

  const handleChange = (event) => {
    const { value, label  } = event.target;

    if (value.toString() === '0') return;

    localStorage.setItem('msoId', parseInt(value));
    localStorage.setItem('msoName',label)
    localStorage.setItem('subdomain',label)
    setShowDashboard(true)
  };

  return (
    showDashboard ? (
      <Dashboard />
    ) : (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl pb-6 font-semibold text-center text-gray-900 border-b-2">
            Select MSO
          </h2>

          <div className="rounded-md shadow-sm">
            <MsoSelectBox
              onChange={(event) => handleChange(event)}
              value={localStorage.getItem('msoId')}
              useTarget={false}
             />
          </div>
        </div>
      </div>
    )
  );
};

export default MsoSelect;
