import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchHistory } from 'features/inventories/inventorySlice';


const InventoryHistoryModal = ({ isOpen, onClose, inventory }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { history } = useSelector((state) => state.inventories)

  const fetchHistoryhData = useCallback(() => {
    dispatch(fetchHistory({ inventoryId: inventory.id, navigate }));
  }, [ dispatch, inventory, navigate ]);

  useEffect(() => {
    fetchHistoryhData();
  }, [dispatch, fetchHistoryhData]);

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 w-full">
        <div className="bg-white p-6 rounded-sm w-full max-w-[600px]">
          <h3 className="text-lg font-semibold mb-4">{`History for ${inventory.serial_number}`}</h3>
          <div className="mb-4">
            <table className='border border-collapse border-gray-200 table-auto text-sm'>
              <thead>
                <tr>
                  <th className='border text-md font-medium text-gray-500 min-w-[200px]'>Description</th>
                  <th className='border text-md font-medium text-gray-500 min-w-[120px]'>User</th>
                  <th className='border text-md font-medium text-gray-500 min-w-[100px]'>Created At</th>
                </tr>
              </thead>
              <tbody>
                {history.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className='border text-md font-medium p-2 text-gray-500  align-top'>{item.description}</td>
                      <td className='border text-md font-medium p-2 text-gray-500  align-top whitespace-nowrap'>{item.action_by}</td>
                      <td className='border text-md font-medium p-2 text-gray-500  align-top whitespace-nowrap'>{item.created_at}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-black rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default InventoryHistoryModal;
