import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchWithAuth } from '../../utils/ApiTools'

const initialState = {
  companyCollection: [],
  companyResource: null,
  companyList: [],
  success: false,
  loading: false,
  pageCount: 0,
  totalItems: 0,
  pageSize: 25,
};

const errorHandler = (error) => {
  return error.response?.data?.message || error.message || 'Something went wrong';
}

export const fetchCompanies = createAsyncThunk('areas/all',
    async ({ page = 1, per = 25, search = '', sorting = [], navigate }) => {

  const msoId = localStorage.getItem('msoId');

  let sortBy = '';
  let direction = '';

  const sortField = sorting.find(s => s.selected);

  sortBy = sortField ? sortField.id : 'id';
  direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

  const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies?page=${page}&per=${per}&search=${search}&sort_by=${sortBy}&direction=${direction}`
  return fetchWithAuth(url, {}, navigate)
});

export const fetchCompanyList = createAsyncThunk(
  'companies/list',
  async () => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/list`);
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  }
);



export const fetchCompany = createAsyncThunk(
  'companies/show',
  async (companyId, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);

export const addCompany = createAsyncThunk(
  'companies/create',
  async ({ formData, navigate }, { rejectWithValue }) => {
    const {
      name,
      phone_number,
      address_1,
      address_2,
      city,
      zip,
      state_id
    } = formData;

    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({ company: {
          name,
          phone_number,
          address_1,
          address_2,
          city,
          zip,
          state_id
        }}),
      }, navigate);

      return response.data;
    } catch (error) {
      console.debug('srv', error.message)
      return rejectWithValue(error.message);
    }
  }
);

export const editCompany = createAsyncThunk(
  'company/edit',
  async ({ companyId, formData, navigate }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ company: formData }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);



const companiesSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    clearCompanies: () => {
      return initialState;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.pending, (state, action) => {
      state.loading = true;
      state.companyCollection = [];
    })
    .addCase(fetchCompanies.fulfilled, (state, action) => {
      state.loading = false;
      state.companyCollection = action.payload.data;
      state.pageCount = action.payload.meta.pagination.total_pages;
      state.totalItems = action.payload.meta.pagination.total_items;
      state.error = null;
    })
    .addCase(fetchCompanies.rejected, (state, action) => {
      state.loading = false;
      state.companyCollection = [];
      state.error = action.error.message;
    })


    .addCase(fetchCompanyList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.companyList = [];
    })
    .addCase(fetchCompanyList.fulfilled, (state, action) => {
      state.loading = false;
      state.companyList = action.payload;
    })
    .addCase(fetchCompanyList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(fetchCompany.pending, (state) => {
      state.loading = true;
      state.companyResource = null;
      state.error = null;
    })
    .addCase(fetchCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.companyResource = action.payload;
    })
    .addCase(fetchCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(addCompany.pending, (state) => {
      state.loading = true;
    })
    .addCase(addCompany.fulfilled, (state, action) => {
      state.success = true
      state.error = false;
      state.loading = false;
    })
    .addCase(addCompany.rejected, (state, action) => {
      console.log(action.payload)
      state.success = false;
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(editCompany.pending, (state) => {
      state.loading = true;
      state.success = false
      state.error = null;
    })
    .addCase(editCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.companyResource = action.payload;
    })
    .addCase(editCompany.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    });
}
});


export const { clearCompanies, setPageSize } = companiesSlice.actions;
export default companiesSlice.reducer;