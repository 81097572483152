import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

export const fetchJobFocisCodes = createAsyncThunk(
  'focisCodes/fetchJobFocisCodes',
  async ({ jobRouteId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/focis_codes`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addMinimalFocisCode = createAsyncThunk(
  'focisCodes/addMinimalFocisCode',
  async ({ jobRouteId, appCodeData }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/focis_codes/create_from_minimal`,
        {
          method: 'POST',
          body: JSON.stringify({
            job_rate_code: appCodeData
          }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const approveFocisCode = createAsyncThunk(
  'focisCodes/approveFocisCode',
  async ({ jobRouteId, focisCodeId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/focis_codes/${focisCodeId}/approve`,
        {
          method: 'POST'
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const denyFocisCode = createAsyncThunk(
  'focisCodes/denyFocisCode',
  async ({ jobRouteId, focisCodeId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/focis_codes/${focisCodeId}/deny`,
        {
          method: 'POST'
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const denyAllFocisCode = createAsyncThunk(
  'focisCodes/denyAllFocisCode',
  async ({ jobRouteId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/focis_codes/deny_all`,
        {
          method: 'POST'
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const approveAllFocisCode = createAsyncThunk(
  'focisCodes/approveAllFocisCode',
  async ({ jobRouteId, companyId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/focis_codes/approve_all`,
        {
          method: 'POST'
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editFocisCode = createAsyncThunk(
  'focisCodes/editFocisCode',
  async ({ jobRouteId, rateCodeId, rateCodeData }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/focis_codes/${rateCodeId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ job_rate_code: rateCodeData }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteFocisCode = createAsyncThunk(
  'focisCodes/deleteFocisCode',
  async ({ jobRouteId, focisCodeId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/focis_codes/${focisCodeId}`,
        {
          method: 'DELETE',
        }
      );

      return focisCodeId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const focisCodesSlice = createSlice({
  name: 'focisCodes',
  initialState: {
    focisCodes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobFocisCodes.pending, (state) => {
        state.focisCodes = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobFocisCodes.fulfilled, (state, action) => {
        state.loading = false;
        state.focisCodes = action.payload;
      })
      .addCase(fetchJobFocisCodes.rejected, (state, action) => {
        state.focisCodes = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addMinimalFocisCode.fulfilled, (state, action) => {
        state.focisCodes.push(action.payload);
      })

      .addCase(editFocisCode.fulfilled, (state, action) => {
        const index = state.focisCodes.findIndex(
          (rateCode) => rateCode.id === action.payload.id
        );
        if (index !== -1) {
          state.focisCodes[index] = action.payload;
        }
        state.loading = false;
      })

      .addCase(denyFocisCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(denyFocisCode.fulfilled, (state, action) => {
        const index = state.focisCodes.findIndex(
          (rateCode) => rateCode.id === action.payload.id
        );
        if (index !== -1) {
          state.focisCodes[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(denyFocisCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(approveFocisCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveFocisCode.fulfilled, (state, action) => {
        const index = state.focisCodes.findIndex(
          (rateCode) => rateCode.id === action.payload.id
        );
        if (index !== -1) {
          state.focisCodes[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(approveFocisCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deleteFocisCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFocisCode.fulfilled, (state, action) => {
        state.focisCodes = state.focisCodes.filter(
          (rateCode) => rateCode.id !== action.payload
        );
        state.loading = false;
      })
      .addCase(deleteFocisCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(denyAllFocisCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(denyAllFocisCode.fulfilled, (state, action) => {
        state.focisCodes = state.focisCodes.map(rateCode => {
          rateCode.status = 'denied'
          return rateCode;
        })
        state.loading = false;
      })
      .addCase(denyAllFocisCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(approveAllFocisCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveAllFocisCode.fulfilled, (state, action) => {
        state.focisCodes = state.focisCodes.map(rateCode => {
          rateCode.status = 'approved'
          return rateCode;
        })
        state.loading = false;
      })
      .addCase(approveAllFocisCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default focisCodesSlice.reducer;
