import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import TosDataTable from '../TosDataTable';
import 'tailwindcss/tailwind.css';

import HeaderTitleBar from '../HeaderTitleBar';

import {
  fetchAreas,
  setPageSize
} from '../../features/areas/areasSlice';


const Areas = () => {
  const navigate = useNavigate();

  const ViewArea = ({ field, row }) => {
    return <Link
      to={`/areas/${row.original.id}`}
      onClick={() => { navigate( `/areas/${row.original.id}` ) }}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
        {row.original[field]}
      </Link>;
  }

  const columns = useMemo(() => {
    if (localStorage.getItem('msoId').toString() == '4') {
      return [
        { header: 'Corp', accessorKey: 'corp_number' },
        {
          header: 'Name',
          accessorKey: 'name',
          cell: ({ getValue, row }) => <ViewArea field={'name'} value={getValue()} row={row} />
        },
        { header: 'City', accessorKey: 'city' },
        { header: 'ZIP', accessorKey: 'zip' },
        { header: 'State', accessorKey: 'state_abbreviation' },
        { header: 'Created At', accessorKey: 'created_at' },
        { header: 'Updated At', accessorKey: 'updated_at' },
      ]
    } else {
      return [
        {
          header: 'Name',
          accessorKey: 'name',
          cell: ({ getValue, row }) => <ViewArea field={'name'} value={getValue()} row={row} />
        },
        { header: 'City', accessorKey: 'city' },
        { header: 'ZIP', accessorKey: 'zip' },
        { header: 'State', accessorKey: 'state_abbreviation' },
        { header: 'Created At', accessorKey: 'created_at' },
        { header: 'Updated At', accessorKey: 'updated_at' },
      ]
    }
  }, []);

  const dispatch = useDispatch();

  const { areaCollection, loading, pageCount, totalItems, pageSize } = useSelector((state) => state.areas);

  const [pageIndex, setPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  const fetchAreasData = useCallback(() => {
    dispatch(fetchAreas({
      page: pageIndex,
      per: pageSize,
      search: searchQuery,
      sorting,
      navigate
    }));
  }, [ dispatch, pageIndex, pageSize, searchQuery, sorting, navigate ]);

  useEffect(() => {
    fetchAreasData();
  }, [dispatch, fetchAreasData]);

  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
    setPageIndex(0);
  };

  const handleCreateClick = () => {
    navigate('/areas/create')
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Areas'
          showBtn={true}
          btnTitle='Create Area'
          btnClick={handleCreateClick}
        />
      </div>

      <TosDataTable
        columns={columns}
        data={areaCollection}
        pageCount={pageCount}
        totalItems={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
        onSortingChange={handleSortingChange}
        onSearchChange={handleSearchChange}
      />
    </>
  );
};

export default Areas;