import React from 'react';
import { useNavigate } from 'react-router-dom';

import 'tailwindcss/tailwind.css';

const BillingComparisonReportBtn = ({ btnTheme }) => {
  const { btnClassName, icoClassName } = btnTheme;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/billing/billing_comparison");
  };

  return (
    <button onClick={handleClick} className={`${btnClassName}`}>
      <svg className={`${icoClassName}`} width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="6" cy="6" r="2" />  <circle cx="18" cy="18" r="2" />  <path d="M11 6h5a2 2 0 0 1 2 2v8" />  <polyline points="14 9 11 6 14 3" />  <path d="M13 18h-5a2 2 0 0 1 -2 -2v-8" />  <polyline points="10 15 13 18 10 21" /></svg>
      <span className="ml-2">Billing Comparison</span>
    </button>
  );
};

export default BillingComparisonReportBtn;