import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { fetchMsos, setPageSize } from '../../features/msos/msosSlice';
import HeaderTitleBar from '../HeaderTitleBar';

import TosDataTable from '../TosDataTable';
import 'tailwindcss/tailwind.css';

const MSOs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, loading, pageCount, totalItems, pageSize } = useSelector((state) => state.msos);

  const ViewMso = ({ field, row }) => (
    <Link
      to={`/msos/${row.original.id}`}
      onClick={() => navigate(`/msos/${row.original.id}`)}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
    >
      {row.original[field]}
    </Link>
  );

  const columns = useMemo(() => [
    { header: 'ID', accessorKey: 'id' },
    {
      header: 'Name',
      accessorKey: 'name',
      cell: ({ row }) => <ViewMso field="name" row={row} />,
    },
    { header: 'Domain', accessorKey: 'domain' },
    { header: 'Created At', accessorKey: 'created_at' },
    { header: 'Updated At', accessorKey: 'updated_at' },
  ], []);

  const [pageIndex, setPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  const fetchMsosData = useCallback(() => {
    dispatch(fetchMsos({
      page: pageIndex + 1,
      per: pageSize,
      search: searchQuery,
      sorting
    }));
  }, [dispatch, pageIndex, pageSize, searchQuery, sorting]);

  useEffect(() => {
    fetchMsosData();
  }, [fetchMsosData]);

  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
    setPageIndex(0);
  };

  const handleCreateClick = () => {
    navigate('/msos/create_mso');
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='MSOs'
          showBtn={true}
          btnTitle='Create MSO'
          btnClick={handleCreateClick}
        />
      </div>
      <TosDataTable
        columns={columns}
        data={data}
        pageCount={pageCount}
        totalItems={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
        onSortingChange={handleSortingChange}
        onSearchChange={handleSearchChange}
      />
    </>
  );
};

export default MSOs;