import React, { Component } from "react";
import { connect } from "react-redux";
import FormSelectBox from "components/formHelpers/FormSelectBox";
import { clearTechnicians, fetchTechList } from "features/technician/techniciansSlice";

class TechniciansSelectBox extends Component {
  componentDidMount() {
    this.fetchTechniciansData();
  }

  componentDidUpdate(prevProps) {
    const { areaId, companyId } = this.props;

    if (areaId !== prevProps.areaId || companyId !== prevProps.companyId) {
      this.fetchTechniciansData();
    }
  }

  fetchTechniciansData = () => {
    const { companyId, areaId, dispatch, navigate } = this.props;

    if (!companyId && !areaId) {
      return;
    }

    dispatch(clearTechnicians());
    dispatch(fetchTechList({ companyId, areaId, navigate }));
  };

  render() {
    const {
      techCollection,
      loading,
      name = "user_id",
      value,
      label,
      useTarget = true,
      onChange,
      horizontal = false,
      isSearchable = true,
    } = this.props;

    return (
      <FormSelectBox
        name={name}
        id={name}
        keyName={name}
        defaultValue={value || "0"}
        label={label}
        valueField={"id"}
        keyField={"name"}
        zeroOption={`Select a Technician`}
        onChange={(event) => onChange(useTarget ? event.target.value : event)}
        data={techCollection}
        horizontal={horizontal}
        loadingState={loading}
        isSearchable={isSearchable}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  techCollection: state.technicians.techCollection,
  loading: state.technicians.loading,
});

export default connect(mapStateToProps)(TechniciansSelectBox);
