import React, { useState, useCallback, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'tailwindcss/tailwind.css';
import FormDatePicker from 'components/formHelpers/FormDatePicker';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import { fetchCompanies } from '../../features/companies/companiesSlice';
import { formatDateToMMDDYYYY } from 'utils/TextTools';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormSubmissionResult from 'components/formHelpers/FormSubmissionResult';
import SelectBox from '../fields/SelectBox';
import { selectAuthUser } from '../../features/auth/authUserSlice';
import { useNavigate } from 'react-router-dom';
import { techPayReport } from  '../../features/billing/billingSlice';

const TechPayReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);
  const authUserRole = authUser.role;

  const msoId = localStorage.getItem('msoId');

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const { techPayCollection, success, error, techPayLoading } = useSelector((state) => state.billing)
  const { companyCollection } = useSelector((state) => state.companies);

  let totalPay = 0.0;
  let trueBilling = 0.0;
  let companyBillingSum = 0.0;

  const canSelectCompany = () => {
    return /admin|mso_user/.test(authUserRole);
  }

  const fetchCompaniesData = useCallback(() => {
    dispatch(fetchCompanies({ page: 1, per: 9999 }));
  }, [ dispatch]);

  useEffect(() => {
    if (!canSelectCompany()) return;

    fetchCompaniesData();
  }, [ fetchCompaniesData, dispatch ])

  const handleStartChange = (date) => {
    setSelectedStartDate(date);
  }

  const handleAreaChange = (e) => {
    const { value } = e.target;
    setSelectedAreaId(value);
  }

  const handleSelectedCompanyId = (value) => {
   // dispatch(setSelectedCompanyId(value));
  };

  const handleGenerate = (e) => {
    e.preventDefault();


    if (msoId !== '3' && msoId !== '4') {
      if (parseInt(selectedAreaId) === 0 || typeof(selectedAreaId) === 'undefined') {
        return;
      }
    }

    dispatch(techPayReport({
      startDate: formatDateToMMDDYYYY(selectedStartDate),
      areaId: selectedAreaId
    }));
  }

  const adjustForCurrency = (field) => {
    if (typeof(field) === 'undefined') field = 0;

    const roundedField = (parseInt(field) || 0).toFixed(2);
    return parseFloat(roundedField).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  const checkHourlyRate = (hourlyRate) => {
    if (hourlyRate === 'NaN') return '$0.00'

    return adjustForCurrency(hourlyRate);
  }

  const renderRows = () => {
    if (techPayLoading) {
      return (
        <tr key={'emptyRow'}>
          <td colSpan={14} className='text-sm text-gray-800'>Loading...</td>
        </tr>
      )
    } else if (!techPayLoading && success && techPayCollection.length === 0) {
      return (
        <tr key={'emptyRow'}>
          <td colSpan={14} className='text-sm text-gray-800'>No report data available...</td>
        </tr>
      )
    } else {
      const payRecords = Object.keys(techPayCollection)
      return payRecords.map((key, index) => {
        const item = techPayCollection[key];
        totalPay = totalPay + parseInt(item.total_pay);
        trueBilling = trueBilling + parseInt(item.true_billing);
        companyBillingSum = companyBillingSum + parseInt(item.company_billing)

        const rowClass = (item.tax_form === 1) ? 'bg-blue-200' : (item.total_hours === 0) ? 'bg-orange-100' : 'bg-green-300'

        return (
          <tr key={index} className={`${rowClass} text-xs`}>
            <td className="p-1">{item.tech_name}</td>
            <td className="p-1">{item.billing}</td>
            <td className="p-1">{item.total_hours}</td>
            {item.tax_form == 1 ? (
              <>
                <td className="p-1">--</td>
                <td className="font-600 text-success">1099</td>
                <td className="p-1">0.00</td>
                <td className="p-1">$0.00</td>
                <td className="p-1">0.00</td>
                <td className="p-1">$0.00</td>
                <td className="p-1">$0.00</td>
              </>
            ) : (
              <>
                <td className="p-1">{item.min_per_hour}</td>
                <td className="p-1">{checkHourlyRate(item.hourly_rate)}</td>
                <td className="p-1">{adjustForCurrency(item.regular_hours)}</td>
                <td className="p-1">{adjustForCurrency(item.regular_pay)}</td>
                <td className="p-1">{adjustForCurrency(item.ot_hours)}</td>
                <td className="p-1">{adjustForCurrency(item.ot_rate)}</td>
                <td className="p-1">{adjustForCurrency(item.ot_pay)}</td>
              </>
            )}
            <td className="p-1">{adjustForCurrency(item.total_pay)}</td>
            <td className="p-1">{adjustForCurrency(item.true_billing)}</td>
            <td className="p-1">{Math.round(item.percentage, 0)}%</td>
            <td className="p-1">{adjustForCurrency(item.company_billing)}</td>
          </tr>
        )
      })
    }
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Tech Pay Report'
          showBtn={false}
        />
        {canSelectCompany() && (
          <div className="mt-4">
            <SelectBox
            data={companyCollection}
            defaultValue={selectedCompanyId}
            onChange={handleSelectedCompanyId}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a Company (All)'}
            />
          </div>
        )}
      </div>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <FormSubmissionResult
          successText={`Export Created`}
          error={error}
        />

        <form onSubmit={handleGenerate} className="space-y-0 mt-6 w-1/2">
          {msoId !== '3' && msoId !== '4' && (
            <div className="grid justift-items-start grid-cols-2 gap-4">
              <AreaSelectBox
                label={'Area'}
                name={'area_id'}
                value={selectedAreaId}
                onChange={handleAreaChange}
                useTarget={false}
                horizontal={true}
              />
            </div>
          )}
          <div className="grid justift-items-start grid-cols-2 gap-4">
            <FormDatePicker
              label={'W/E Date'}
              name="we_date"
              onChange={handleStartChange}
              placeholder="Enter a Start Date"
              value={selectedStartDate}
              horizontal={true}
            />
          </div>
          <div className="flex md:items-center mb-6 space-x-1">
            <button className="bg-blue-600 text-white px-4 p-1 inline-flex items-center ">
              {techPayLoading && (
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              )}
              Get Report
            </button>
          </div>
        </form>
      </div>

      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <table className="w-full divide-y divide-gray-200 mt-1">
          <thead className="bg-gray-50">
            <tr>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Tech</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Billing</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Total Hours</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Min Per Hour</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Hourly Rate</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Regular Hours</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Regular Pay</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>OT Hours</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>OT Rate</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>OT Pay</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Total Pay</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>True Billing</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>%</th>
                <th className='p-2 text-left text-xs font-medium text-gray-500 uppercase'>Company Billing</th>
            </tr>
          </thead>
          <tbody>
            {renderRows()}
          </tbody>
          <tfoot>
            <tr className="text-xs font-semibold">
              <td colSpan="9" className="p-2"></td>
              <td className="p-2">Totals</td>
              <td className="p-2">{adjustForCurrency(totalPay)}</td>
              <td className="p-2">{adjustForCurrency(trueBilling)}</td>
              <td className="p-2">&nbsp;</td>
              <td className="p-2">{adjustForCurrency(companyBillingSum)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default TechPayReport