import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TosForm from 'components/formHelpers/TosForm';
import FormTextArea from 'components/formHelpers/FormTextArea';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import { transferInventory } from 'features/inventories/inventorySlice';

const TransferInventoryModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    serial_numbers: '',
    area_id: '',
    inventory_type_id: ''
  });

  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [areaName, setAreaName] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const handleAreaChange = (e) => {
    const { value, label } = e.target;
    setSelectedAreaId(value);
    setAreaName(label)

    setFormData((prevFormData) => ({
      ...prevFormData,
      area_id: value,
    }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const meta = { area_name: areaName }

    dispatch(transferInventory({ formData, meta, navigate }))
    onClose()
  }

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-full max-w-md">
          <h3 className="text-lg font-semibold mb-4">
            Transfer Inventory (Area to Area)
          </h3>
          <TosForm onSubmit={handleSubmit}>
            <div className="mb-4">
              <FormTextArea
                label={'Serial Numbers'}
                name="serial_numbers"
                value={formData.serial_numbers}
                onChange={handleChange}
                placeholder="Serial Numbers"
                formErrors={formErrors}
                rows={3}
              />
              <AreaSelectBox
                label={'Area'}
                name={'area_id'}
                value={selectedAreaId}
                onChange={handleAreaChange}
                useTarget={false}
                warehouseOnly={localStorage.getItem('msoId') === '4'}
              />
            </div>


            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-black rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Save
              </button>
            </div>
          </TosForm>
        </div>
      </div>
    )
  );
};

export default TransferInventoryModal;
