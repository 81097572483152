import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import { setSuccess, setError, fetchMso } from 'features/msos/msosSlice';

import 'tailwindcss/tailwind.css';

const ViewMso = () => {
  const { msoId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { msoResource, loading, error } = useSelector((state) => state.msos);

  useEffect(() => {
    dispatch(fetchMso(msoId));
  }, [dispatch, msoId]);


  setSuccess(false)
  setError(false)

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleBack = () => {
    navigate(-2);
  };

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title={`Mso Details`}
          showBtn={true}
          btnTitle={'Back'}
          btnClick={handleBack}
        />

        {(loading || msoResource === null) ? (
          <div>Loading...</div>
        ) : error ? (
          <div className="text-red-500">Error loading MSO details.</div>
        ) : (

          <div className="mt-6 w-1/2">
            <div className="mt-6 w-full mb-5">
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">MSO</p>
                  <p>{msoResource?.name || 'N/A'}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Company</p>
                  <p>{msoResource?.domain || 'N/A'}</p>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={() => navigate(`/msos/${msoId}/edit`)}
                className="bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Edit MSO
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewMso;
