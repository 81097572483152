import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormSelectBox from "components/formHelpers/FormSelectBox";
import { fetchVendors } from "features/users/usersSlice";

const VendorSelectBox = ({ value, name="vendorId", label, onChange, horizontal=false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { vendorCollection, loading } = useSelector((state) => state.users);

  const fetchData = useCallback(() => {
    if (vendorCollection?.length > 0) return;


    dispatch(fetchVendors());
  }, [dispatch, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    vendorCollection && (
      <FormSelectBox
        name={name}
        id={name}
        label={label}
        defaultValue={value}
        valueField={'id'}
        keyField={'name'}
        zeroOption={'Select a Vendor'}
        onChange={onChange}
        data={vendorCollection}
        horizontal={horizontal}
        loadingState={loading}
      />
    )
  )
}

export default VendorSelectBox;