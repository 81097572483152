import React, { Component } from "react";
import { connect } from "react-redux";
import FormSelectBox from "components/formHelpers/FormSelectBox";
import { fetchInventoryTypes } from "features/inventories/inventoryTypesSlice";

class InventoryTypesSelectBox extends Component {
  componentDidMount() {
    this.fetchTypesData();
  }

  fetchTypesData = () => {
    const { dispatch } = this.props;
    dispatch(fetchInventoryTypes());
  };

  render() {
    const { types,
      loading,
      name = "inventoryTypeId",
      value,
      label,
      useTarget = true,
      onChange,
      horizontal = false
    } = this.props;

    return (
      <FormSelectBox
        name={name}
        id={name}
        keyName={name}
        defaultValue={value || "0"}
        label={label}
        valueField={"id"}
        keyField={"name"}
        zeroOption={`Select an Inventory Type`}
        onChange={(event) => onChange(useTarget ? event.target.value : event)}
        data={types}
        horizontal={horizontal}
        loadingState={loading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  types: state.inventoryTypes.types,
  loading: state.inventoryTypes.loading,
});

export default connect(mapStateToProps)(InventoryTypesSelectBox);
