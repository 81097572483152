import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormTextInput from 'components/formHelpers/FormTextInput';
import FormSubmissionResult from 'components/formHelpers/FormSubmissionResult';
import FormStatesSelectBox from 'components/formHelpers/FormStatesSelectBox';
import { editArea, fetchArea } from 'features/areas/areasSlice';

import 'tailwindcss/tailwind.css';

const EditArea = () => {
  const { areaId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    areaResource,
    success,
    error,
    loading
  } = useSelector((state) => state.areas);

  const [warehouse, setWarehouse] = useState(false);
  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    name: '',
    address_1: '',
    address_2: '',
    city: '',
    state_id: 0,
    zip: '',
    min_rate: 0,
    min_wage: 0,
    pay_percentage: 0,
    warehouse: false
  });

  useEffect(() => {
    dispatch(fetchArea(areaId));
  }, [dispatch, areaId]);

  useEffect(() => {
    if (areaResource) {
      setWarehouse(areaResource.warehouse)
      setFormData({
        name: areaResource.name || '',
        address_1: areaResource.address_1,
        address_2: areaResource.address_2 || '',
        city: areaResource.city || '',
        state_id: areaResource?.state?.id || 0,
        zip: areaResource.zip || '',
        min_rate: areaResource.min_rate,
        min_wage: areaResource.min_wage,
        pay_percentage: areaResource.pay_percentage || 0,
        warehouse: areaResource.warehouse === true
      });
    }

  }, [areaResource]);


  const validateForm = () => {
    let newErrors = {};

    if (!formData.name) {
      newErrors.name = 'A name is required';
    }

    setFormErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const newData = formData;
    if (name === 'warehouse') {
      newData.warehouse = checked;
    } else {
      newData[name] = value;
    }

    setFormData(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors({});
    if (validateForm()) {
      dispatch(editArea({areaId, formData}))
    }
  };

  const handleBack = () => {
    navigate('/areas');
  };

  return (
    !loading && (
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title={'Edit Area'}
          showBtn={true}
          btnTitle={'Back'}
          btnClick={handleBack}
        />

        <FormSubmissionResult
          successText={`Area "${formData.name}" Updated`}
          success={success}
          error={error}
        />

        <form onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">
          <FormTextInput
            label={'Name'}
            name={'name'}
            id={'name'}
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter a Name"
            formErrors={formErrors}
            required={true}
          />

          <FormTextInput
            label={'Address 1'}
            name="address_1"
            value={formData.address_1}
            onChange={handleChange}
            placeholder="Enter an Address"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Address (continued)'}
            name="address_2"
            value={formData.address_2}
            onChange={handleChange}
            placeholder="Enter an Address (continued)"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'City'}
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="Enter a City"
            formErrors={formErrors}
          />

          <FormStatesSelectBox
            state_id={formData.state_id || 0}
            required={false}
            formErrors={formErrors}
            onChange={handleChange}
          />

          <FormTextInput
            label={'Zip'}
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            placeholder="Enter a Zip Code"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Minimum Rate'}
            name="min_rate"
            value={formData.min_rate}
            onChange={handleChange}
            placeholder="Enter a Minimum Rate"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Minimum Wage'}
            name="min_wage"
            value={formData.min_wage}
            onChange={handleChange}
            placeholder="Enter a Minimum Wage"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Pay percentage'}
            name="pay_percentage"
            value={formData.pay_percentage}
            onChange={handleChange}
            placeholder="Enter an Area Pay Percentage"
            formErrors={formErrors}
          />

          <div className="flex items-center mt-5">
            <input
              id="warehouse"
              name="warehouse"
              type="checkbox"
              defaultChecked={formData.warehouse}
              onChange={(e) => {
                setWarehouse(e.target.checked)
                handleChange(e);
              }}
              className="border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />

            <label htmlFor="warehouse" className="ms-2 text-sm font-medium text-gray-700">Warehouse</label>
          </div>

          <div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Update Area
            </button>
          </div>
        </form>
      </div>
    )
  );
};

export default EditArea;
