import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

const initialState = {
  hourlyApprovalCollection: [],
  hourlyApprovalResource: null,
  selectedCompanyId: 0,
  selectedUserTime: [],
  selectedAreaId: 0,
  billing_statuses: [],
  keeperId:0,
  statuses: [],
  loading: false,
  success: false,
  error: null,
  rateCodeList: []
};

const errorHandler = (error) => {
  return error.response?.data?.message || error.message || 'Something went wrong';
}

export const fetchHourlyApproval = createAsyncThunk('hourlyApproval/all',
    async ({
      companyId,
      entryDate,
      areaId,
      userId,
      navigate
  }) => {

  const msoId = localStorage.getItem('msoId');

  let queryParams = new URLSearchParams({
    entry_date: entryDate,
    area_id: areaId
  });

  if (userId) queryParams.append('user_id', userId);
  if (companyId) queryParams.append('company_id', companyId);

  const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/hours_approval?${queryParams.toString()}`
  return fetchWithAuth(url, {}, navigate)
});

export const fetchCompanyList = createAsyncThunk(
  'companies/list',
  async () => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/list`);
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  }
);

export const addHourly = createAsyncThunk(
  'hourlyApproval/create',
  async ({ formData, companyId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/hours_approval`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          hours_approval: formData
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editHourly = createAsyncThunk(
  'hourlyApproval/edit',
  async ({ timeKeeperId, formData }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/hours_approval/${timeKeeperId}`;
      const response = await fetchWithAuth(url, {
        method: 'PATCH',
        body: JSON.stringify({
          hours_approval: formData
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const hourlyApprovalSlice = createSlice({
  name: 'hourlyApproval',
  initialState,
  reducers: {
    clearHourlyApproval: () => {
      return initialState;
    },
    setSelectedCompanyId: (state, action) => {
      state.selectedCompanyId = action.payload;
    },
    setSelectedAreaId: (state, action) => {
      state.selectedAreaId = action.payload;
    },
    setSelectedUserTime: (state, action) => {
      state.selectedUserTime = action.payload;
    },
    setKeeperId: (state, action) => {
      state.keeperId = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHourlyApproval.pending, (state) => {
      state.error = false;
      state.loading = true;
    })
    .addCase(fetchHourlyApproval.fulfilled, (state, action) => {
      state.loading = false;
      state.hourlyApprovalCollection = action.payload;
      state.error = false;
    })
    .addCase(fetchHourlyApproval.rejected, (state, action) => {
      state.loading = false;
      state.hourlyApprovalCollection = null;
      state.error = action.error.message;
    })
    .addCase(addHourly.pending, (state) => {
      state.loading = true;
    })
    .addCase(addHourly.fulfilled, (state, action) => {
      state.success = true
      state.error = false;
      state.loading = false;
    })
    .addCase(addHourly.rejected, (state, action) => {

      state.success = false;
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(editHourly.pending, (state) => {
      state.loading = true;
      state.success = false
      state.error = null;
    })
    .addCase(editHourly.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.hourlyApprovalResource = action.payload;
    })
    .addCase(editHourly.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    });
  },
});

export const {
  setSelectedCompanyId,
  setSelectedAreaId,
  setSelectedUserTime,
  setKeeperId,
  clearHourlyApproval
} = hourlyApprovalSlice.actions;

export default hourlyApprovalSlice.reducer;