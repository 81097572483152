import React, { Component } from "react";
import { connect } from "react-redux";
import FormSelectBox from "components/formHelpers/FormSelectBox";
import { clearCompanies, fetchCompanyList } from "features/companies/companiesSlice";

class CompanySelectBox extends Component {
  componentDidMount() {
    this.fetchCompaniesData();
  }

  fetchCompaniesData = () => {
    const { dispatch } = this.props;
    dispatch(clearCompanies());
    dispatch(fetchCompanyList());
  };

  render() {
    const { companyList, loading, name = "companyId", value, label, useTarget = true, onChange, horizontal = false } = this.props;

    return (
      <FormSelectBox
        name={name}
        id={name}
        keyName={name}
        defaultValue={value}
        label={label}
        valueField={"id"}
        keyField={"name"}
        zeroOption={`Select a Company (All)`}
        onChange={(event) => onChange(useTarget ? event.target.value : event)}
        data={companyList}
        horizontal={horizontal}
        loadingState={loading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  companyList: state.companies.companyList,
  loading: state.companies.loading,
});

export default connect(mapStateToProps)(CompanySelectBox);
