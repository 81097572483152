import React, { Component } from "react";
import { connect } from "react-redux";
import FormSelectBox from "components/formHelpers/FormSelectBox";
import { clearAreas, fetchAllAreas } from "features/areas/areasSlice";

class AreaSelectBox extends Component {
  componentDidMount() {
    this.fetchAreasData();
  }

  fetchAreasData = () => {
    const { dispatch, navigate, warehouseOnly } = this.props;
    dispatch(clearAreas());
    dispatch(fetchAllAreas({ page: 1, per: 9999, warehouseOnly, navigate }));
  };

  render() {
    const { areaList, loading, name = "areaId", value, label, useTarget = true, onChange, horizontal = false } = this.props;

    return (
      <FormSelectBox
        name={name}
        id={name}
        keyName={name}
        defaultValue={value || "0"}
        label={label}
        valueField={"id"}
        keyField={"name"}
        zeroOption={`Select an Area`}
        onChange={(event) => onChange(useTarget ? event.target.value : event)}
        data={areaList}
        horizontal={horizontal}
        loadingState={loading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  areaList: state.areas.areaList,
  loading: state.areas.loading,
});

export default connect(mapStateToProps)(AreaSelectBox);
