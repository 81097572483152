const getMsoId = (msoName) => {
  switch(msoName) {
    case 'admin':
      return 0;
      break;
    case 'abb':
      return 1;
      break;
    case 'rcn':
      return 2;
      break;
    case 'wired':
      return 3;
      break;
    case 'altice':
      return 4;
      break;
    case 'spectrum':
      return 5;
      break;
    default:
      return null;
  }
}

export const getSubDomain = () => {
  const { hostname } = window.location;
  const parts = hostname.split('.');

  if (localStorage.getItem('msoId') !== null) {
    return;
  }

  if(parts[1] === 'localhost' && parts.length === 2) {
    localStorage.setItem('subdomain', parts[0]);
    localStorage.setItem('msoId', getMsoId(parts[0]));
    return parts[0];
  }

  if (parts.length >= 3) {
    localStorage.setItem('subdomain', parts[0]);
    localStorage.setItem('msoId', getMsoId(parts[0]));
    return parts[0];
  }

  //localStorage.setItem('msoId', null);
};
