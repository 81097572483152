import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TosForm from 'components/formHelpers/TosForm';
import FormTextArea from 'components/formHelpers/FormTextArea';
import { returnInventory } from 'features/inventories/inventorySlice';
import FormSelectBox from 'components/formHelpers/FormSelectBox';

const ReturnInventoryModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    serial_numbers: '',
    action_status_id: 3,
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(formData.action_status_id === '0') return;

    dispatch(returnInventory({ formData, navigate }))
    onClose()
  }

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-full max-w-md">
          <h3 className="text-lg font-semibold mb-4">
            Return Inventory
          </h3>
          <TosForm onSubmit={handleSubmit}>
            <div className="mb-4">
              <FormTextArea
                label={'Serial Numbers'}
                name="serial_numbers"
                value={formData.serial_numbers}
                onChange={handleChange}
                placeholder="Serial Numbers"
                formErrors={formErrors}
                rows={3}
              />
            </div>

            <FormSelectBox
              name='action_status_id'
              data={[
                { id: 1, name: 'Return to Warehouse' },
                { id: 4, name: 'Return to MSO' }
              ]}
              defaultValue={formData.action_status_id}
              onChange={(e) => handleChange(e) }
              valueField={'id'}
              keyField={'name'}
              zeroOption={'Select a Return Type'}
            />

            <div className="flex justify-end space-x-4 pt-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-black rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Save
              </button>
            </div>
          </TosForm>
        </div>
      </div>
    )
  );
};

export default ReturnInventoryModal;
