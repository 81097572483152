import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

import 'tailwindcss/tailwind.css';

const TimeKeeperBtn = ({ btnTheme }) => {
  const { btnClassName, icoClassName } = btnTheme;
  const navigate = useNavigate();
  const formattedDate = moment().format('YYYY-MM-DD');
  const authUser = useSelector((state) => state.authUser);

  const handleClick = () => {
    const url=`/billing/time_keeper?userId=${authUser.id}&entryDate=${formattedDate}`;
    navigate(url);
  };

  return (
    <button onClick={handleClick} className={`${btnClassName}`}>
      <svg className={`${icoClassName}`} width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
      <span className="ml-2">Time Keeper</span>
    </button>
  );
};

export default TimeKeeperBtn;