import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchWithAuth } from '../../utils/ApiTools'

const initialState = {
  data: [],
  success: false,
  loading: false,
  msoResource: null,
  pageCount: 0,
  totalItems: 0,
  pageSize: 25,
  msoId: localStorage.getItem('msoId')
};

export const fetchMsos = createAsyncThunk('msos/all',
    async ({ page = 1, per = 25, search = '', sorting = [], navigate }) => {

  let sortBy = '';
  let direction = '';

  const sortField = sorting.find(s => s.selected);

  sortBy = sortField ? sortField.id : 'id';
  direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

  const url = `${process.env.REACT_APP_API_URL}/api/v1/msos?page=${page}&per=${per}&search=${search}&sort_by=${sortBy}&direction=${direction}`

  return fetchWithAuth(url, {}, navigate);
});

export const addMso = createAsyncThunk(
  'msos/create',
  async ({ formData, navigate }, { rejectWithValue }) => {
    const { name, domain } = formData;

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({ mso: {
          name,
          domain
        }}),
      }, navigate);

      return response.data;
    } catch (error) {
      console.debug('srv', error.message)
      //return error.message
      return rejectWithValue(error.message);
    }
  }
);

export const editMso = createAsyncThunk(
  'msos/editMso',
  async ({ msoId, formData, navigate }, { rejectWithValue }) => {
    const { name, domain } = formData;

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}`;
      const response = await fetchWithAuth(url, {
        method: 'PUT',
        body: JSON.stringify({ mso: {
          name,
          domain
        }}),
      }, navigate);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMso = createAsyncThunk(
  'user/fetchMso',
  async (msoId, { rejectWithValue }) => {
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const msosSlice = createSlice({
  name: 'mso',
  initialState,
  reducers: {
    clearMsos: () => {
      return initialState;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMsos.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchMsos.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.pageCount = action.payload.meta.pagination.total_pages;
      state.totalItems = action.payload.meta.pagination.total_items;
      state.error = null;
    })
    .addCase(fetchMsos.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    })
    .addCase(addMso.pending, (state) => {
      state.loading = true;
    })
    .addCase(addMso.fulfilled, (state, action) => {
      state.success = true
      state.error = false;
      state.loading = false;
    })
    .addCase(addMso.rejected, (state, action) => {
      state.success = false;
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(editMso.pending, (state) => {
      state.loading = true;
      state.success = false
      state.error = null;
    })
    .addCase(editMso.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.msoResource = action.payload;
    })
    .addCase(editMso.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    })
    .addCase(fetchMso.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchMso.fulfilled, (state, action) => {
      state.loading = false;
      state.msoResource = action.payload;
    })
    .addCase(fetchMso.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
}
});


export const { clearMsos, setError, setSuccess, setPageSize } = msosSlice.actions;
export default msosSlice.reducer;