import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TosForm from 'components/formHelpers/TosForm';
import FormTextArea from 'components/formHelpers/FormTextArea';
import { editMissingAccountNumber } from 'features/inventories/inventorySlice';
import FormTextInput from 'components/formHelpers/FormTextInput';

const EditAccountNumberModal = ({ isOpen, onClose, inventoryId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    account_number: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(formData.account_number === '') return;

    dispatch(editMissingAccountNumber({ inventoryId, formData, navigate }))
    onClose()
  }

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-full max-w-md">
          <h3 className="text-lg font-semibold mb-4">
            Missing Account number {inventoryId}
          </h3>
          <TosForm onSubmit={handleSubmit}>
            <div className="mb-4">
              <FormTextInput
                label={'Account Number'}
                name="account_number"
                value={formData.account_number}
                onChange={handleChange}
                placeholder="Enter Account Number"
                formErrors={formErrors}
              />
            </div>

            <div className="flex justify-end space-x-4 pt-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-black rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Save
              </button>
            </div>
          </TosForm>
        </div>
      </div>
    )
  );
};

export default EditAccountNumberModal;
