import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fetchJobRouteById,
  fetchJobRouteInventory,
  editJobRoute,
  hhcCheck,
  clearJobRoute
} from '../../features/jobRoutes/jobRouteSlice';

import JobDetailsNotes from './components/JobDetailNotes';
import JobDetailsAppCodes from './components/JobDetailsAppCodes';
import JobDetailsFocisCodes from './components/JobDetailsFocisCodes';
import JobRouteBillingStatusBtn from './components/buttons/JobRouteBillingStatusBtn';
import EditableTextField from '../fields/EditableTextField';
import EditableDateField from '../fields/EditableDateField';
import { titlize } from 'utils/TextTools';
import HeaderTitleBar from 'components/HeaderTitleBar';
import Postcall from './components/Postcall';

import 'tailwindcss/tailwind.css';

const JobDetails = () => {
  const { jobId } = useParams();
  const msoId = localStorage.getItem('msoId').toString();

  const authUser = useSelector((state) => state.authUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: jobRoute,
    inventories,
    hhcLoading,
    loading,
    error
  } = useSelector((state) => state.jobRoute);

  useEffect(() => {
    dispatch(fetchJobRouteById({ jobRouteId: jobId, navigate }));

    return () => {
      dispatch(clearJobRoute());
    };
  }, [dispatch, jobId, navigate]);

  useEffect(() => {
    if (!jobRoute) return;
    dispatch(fetchJobRouteInventory({ jobRouteId: jobRoute.id, navigate }));
  }, [ dispatch, jobRoute ])

  const handleApprovalChange = () => {
    const newStatus = (jobRoute.billing_status === 'approved') ? 0 : 1
    dispatch(editJobRoute({ jobRouteId: jobId, jobRouteParams: { billing_status: newStatus} }));
  }

  const handleJobNumberUpdate = (job_number) => {
    dispatch(editJobRoute({ jobRouteId: jobId, jobRouteParams: { job_number: job_number} }));
  }

  const handleJobDateUpdate = (job_date) => {
    dispatch(editJobRoute({ jobRouteId: jobId, jobRouteParams: { job_date: job_date.toString()} }));
  }

  const handleBack = () => {
    navigate(-2);
  }

  const handleHHCClick = () => {
    dispatch(hhcCheck({ jobRouteId: jobId, accountNumber: jobRoute.account_number }))
  }

  const dateColumns = {
    'Received on': 'date_received',
    'Issued on': 'date_issued',
    'Installed on': 'date_installed',
  }

  const dataColumnIds = {
    'date_received': 1,
    'date_received': 5,
    'date_issued': 2,
    'date_installed': 8
  }

  const DateList = (row) => {
    return (
      <ul key={row.id}>
        {Object.keys(dateColumns).map((key) => {
          const value = dateColumns[key]
          if (row.row[value] && row.row.action_status_id === dataColumnIds[value]) {
            return (
              <li key={Date.now() + Math.random()}>{key}: {row.row[value]}</li>
            )
          }
        })}
      </ul>
    )
  }

  const renderInventory = () => {
    return inventories && inventories.length > 0 ? (
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b text-left">Serial Name</th>
            <th className="py-2 px-4 border-b text-left">Type</th>
            <th className="py-2 px-4 border-b text-left">Status</th>
            <th className="py-2 px-4 border-b text-left">Dates</th>
            <th className="py-2 px-4 border-b text-left">Grade</th>
            <th className="py-2 px-4 border-b text-left">Frequency</th>
            <th className="py-2 px-4 border-b text-left">Receive</th>
            <th className="py-2 px-4 border-b text-left">Transmit</th>
            <th className="py-2 px-4 border-b text-left">Carrier</th>
          </tr>
        </thead>
        <tbody>
            {Object.keys(inventories).map((key, index) => {
              const currentInventory = inventories[key];
              let rowClass = '';

              switch(currentInventory.grade) {
                case 'Pass':
                  rowClass = 'bg-green-300 text-gray-600';
                  break;
                case 'Fail':
                  rowClass = 'bg-red-600 text-white';
                  break;
                case 'N/A':
                  rowClass = 'bg-green-300 text-gray-600';
                  break;
                default:
                  rowClass = '';
              }

              return (
                <tr key={index} className={rowClass}>
                  <td className="py-2 px-4 border-b text-left text-sm">{currentInventory.serial_number}</td>
                  <td className="py-2 px-4 border-b text-left text-sm">{currentInventory.type_name}</td>
                  <td className="py-2 px-4 border-b text-left text-sm">{currentInventory.action_status}</td>
                  <td className="py-2 px-4 border-b text-left text-sm">
                    <DateList row={currentInventory} />
                  </td>
                  <td className="py-2 px-4 border-b text-left text-sm">{currentInventory.grade}</td>
                  <td className="py-2 px-4 border-b text-left text-sm">{currentInventory.frequency}</td>
                  <td className="py-2 px-4 border-b text-left text-sm">{currentInventory.receive}</td>
                  <td className="py-2 px-4 border-b text-left text-sm">{currentInventory.transmit}</td>
                  <td className="py-2 px-4 border-b text-left text-sm">{currentInventory.carrier}</td>
                </tr>
            )})}
        </tbody>
      </table>
    ) : (
       <p className='text-sm text-gray-500'>No Inventory available</p>
    )
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  if (!jobRoute) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-gray-500">No job data available</div>
      </div>
    );
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title={'Job Details'}
          showBtn={true}
          btnTitle={'Back'}
          btnClick={handleBack}
        />

        <div>
          <table className="w-full divide-y divide-gray-200">
            <tbody>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Status</td>
                <td className="px-6 py-3 text-gray-500" colSpan="3">{titlize(jobRoute.status)}</td>
              </tr>
              {localStorage.getItem('msoId').toString() === '4' && (
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Corp</td>
                <td className="px-6 py-3 text-gray-500" colSpan="3">{jobRoute.corp_number}</td>
              </tr>
              )}
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Job Number</td>
                <td className="px-6 py-3 text-gray-500">
                  <EditableTextField
                    fieldValue={jobRoute.job_number}
                    btnText={'Edit Job Number'}
                    onSave={handleJobNumberUpdate} />
                </td>
                <td className="px-6 py-3 font-semibold text-gray-700">Date</td>
                <td className="px-6 py-3 text-gray-500">
                  <EditableDateField
                    fieldValue={new Date(jobRoute.job_date).toLocaleDateString()}
                    btnText={'Edit Job Date'}
                    onSave={handleJobDateUpdate} />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Technician</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute?.full_name}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">Address</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.address}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Timeframe</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.time_frame}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">City</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.city}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Start Time</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.time_started ? new Date(jobRoute.time_started).toUTCString().substring(17,25) : 'N/A'}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">End Time</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.time_completed ? new Date(jobRoute.time_completed).toUTCString().substring(17,25) : 'N/A'}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Work Type</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.job_type}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">Zip</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.zip}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Home Phone</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.home_phone}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">Other Phone</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.other_phone}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Billing Status</td>
                <td className="px-6 py-3 text-gray-500" colSpan="3">
                  <JobRouteBillingStatusBtn
                    status={jobRoute.billing_status}
                    handler={handleApprovalChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Account Number</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.account_number}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">Total Notes</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.total_job_notes}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Points</td>
                <td className="px-6 py-3 text-gray-500" colSpan={2}>{jobRoute.points}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <JobDetailsNotes jobRoute={jobRoute}  />
      </div>
      {msoId !== '4' && msoId !== '3' ? (
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <JobDetailsAppCodes jobRoute={jobRoute}  />
      </div>
      ) : (
        <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <JobDetailsFocisCodes jobRoute={jobRoute}  />
        </div>
      )}

      {authUser.user_type.match(/admin|corporate|dispatch|supervisor/) && (
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <Postcall jobRoute={jobRoute}  />
      </div>
      )}

      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <div className="flex justify-between items-center border-b pb-2">
          <h4 className="text-lg font-semibold capitalize">
            Associated Inventory
          </h4>
          {(msoId === '4' || msoId === '3') && (
          <button
              onClick={() => handleHHCClick()}
              className="bg-transparent hover:bg-blue-500 text-xs text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            >
              Run HHC
          </button>
          )}
        </div>

        {hhcLoading ? (<p className='text-gray-500'>Running Healthcheck...</p>) : renderInventory() }
      </div>
    </>
  );
};

export default JobDetails;
