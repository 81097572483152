import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';

import {
  setSuccess,
  setError,
  fetchMsoAppCode
} from 'features/appCodes/appCodesSlice';
import 'tailwindcss/tailwind.css';

const ViewCode = () => {
  const { codeId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { appResource, loading, error } = useSelector((state) => state.appCodes);

  useEffect(() => {
    dispatch(fetchMsoAppCode({codeId, navigate}));
  }, [dispatch, codeId]);

  setSuccess(false)
  setError(false)

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleBack = () => {
    navigate('/app_codes');
  };

  return (
    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={`Code Details: ${appResource?.name || ''}`}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      {(loading || appResource === null) ? (
        <div>Loading...</div>
      ) : error ? (
        <div className="text-red-500">Error loading Code details.</div>
      ) : (

          <div className="mt-6 w-1/2">
            <div className="mt-6 w-full mb-5">
              <div className="grid grid-cols-2 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Code</p>
                  <p>{appResource?.code || 'N/A'}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Code Type</p>
                  <p>{appResource?.code_type || 'N/A'}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Value</p>
                  <p>{appResource?.value || 'N/A'}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">quantity_locked</p>
                  <p>{appResource?.quantity_locked.toString() || 'N/A'}</p>
                </div>
              </div>
            </div>

          <div className="mt-6">
            <button
              onClick={() => navigate(`/app_codes/${codeId}/edit`)}
              className="bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Edit Code
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default ViewCode;
