import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import TosDataTable from '../TosDataTable';
import 'tailwindcss/tailwind.css';
import HeaderTitleBar from 'components/HeaderTitleBar';

import { fetchCompanies, setPageSize } from 'features/companies/companiesSlice';

const Companies = () => {


  const handleEditClick = (companyId) => {

    navigate(`/companies/${companyId}/edit`);
  };

  const EditCompanyLink = ({ cell, row }) => {
    return <Link
      to={`/companies/${row.original.id}/edit`}
      onClick={() => { handleEditClick(row.original.id) }}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
        {row.original.name}
      </Link>;
  }

  const columns = useMemo(() => [
    { header: 'ID', accessorKey: 'id' },
    {
      header: 'Name',
      accessorKey: 'name',
      cell: ({ getValue, row }) => <EditCompanyLink value={getValue()} row={row} />
    },
    { header: 'Phone Number', accessorKey: 'phone_number' },
    { header: 'City', accessorKey: 'city' },
    { header: 'ZIP', accessorKey: 'zip' },
    { header: 'State', accessorKey: 'state.abbreviation' },
    { header: 'Created At', accessorKey: 'created_at' },
    { header: 'Updated At', accessorKey: 'updated_at' },
  ], []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { companyCollection, loading, pageCount, totalItems, pageSize } = useSelector((state) => state.companies);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  const fetchCompaniesData = useCallback(() => {
    dispatch(fetchCompanies({
      page: pageIndex + 1,
      per: pageSize,
      search: searchQuery,
      sorting
    }));
  }, [ dispatch, pageIndex, pageSize, searchQuery, sorting ]);

  useEffect(() => {
    fetchCompaniesData();
  }, [ fetchCompaniesData, dispatch ])

  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
    setPageIndex(0);
  };

  const handleCreateClick = () => {
    navigate('/companies/create_company');
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Companies'
          showBtn={true}
          btnTitle='Create Company'
          btnClick={handleCreateClick}
        />
      </div>

      <TosDataTable
        columns={columns}
        data={companyCollection}
        pageCount={pageCount}
        totalItems={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
        onSortingChange={handleSortingChange}
        onSearchChange={handleSearchChange}
      />
    </>
  );
};

export default Companies;