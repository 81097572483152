import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestions,
  fetchPostcall,
  editPostcall,
  deletePostcallNote
} from 'features/jobRoutes/postcallsSlice';
import 'tailwindcss/tailwind.css';
import TosForm from 'components/formHelpers/TosForm';
import PostcallNoteModal from './modals/PostcallNoteModal';
import FormSubmissionResult from 'components/formHelpers/FormSubmissionResult';
import moment from 'moment';

const Postcall = ({ jobRoute }) => {
  const dispatch = useDispatch();
  const jobRouteId = jobRoute.id;

  const [showModal, setShowModal] = useState(false);
  const [answers, setAnswers] = useState({});
  const [existingNote, setExistingNote] = useState(null);

  const {
    questions,
    loading,
    savedAnswered,
    error,
    postcall
  } = useSelector(state => state.postcalls);

  useEffect(() => {
    dispatch(fetchPostcall({ jobRouteId }));
  }, [dispatch, jobRouteId ]);

  useEffect(() => {
    dispatch(fetchQuestions({ jobRouteId: jobRoute.id }));
  }, [dispatch, fetchQuestions]);

  const handleAnswer = (e, questionId) => {
    const selectedAnswer = e.target.value;
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: selectedAnswer
    }));
  };

  useEffect(() => {
    if (questions) {
      const defaultAnswers = {};
      questions.forEach(question => {
        defaultAnswers[question.id] = "0";
      });

      if (postcall && postcall.postcall_answers) {
        postcall.postcall_answers.forEach(answer => {
          defaultAnswers[answer.postcall_question_id] = answer.answer.toString();
        })
      }

      setAnswers(defaultAnswers);
    }
  }, [questions, postcall]);

  const handleEditNote = (note) => {
    setExistingNote(note);
    setShowModal(true);
  };

  const handleDeleteNote = async (note) => {
    const noteId = note.id;

    await dispatch(deletePostcallNote({ jobRouteId, postcallId: postcall.id, noteId }));

    setExistingNote(null);
    dispatch(fetchPostcall({ jobRouteId }));
  }

  const handleAddNote = () => {
    setExistingNote('');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setExistingNote(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { job_route_id: jobRouteId }
    data['postcall_answers_attributes'] = Object.keys(answers).map((key) => {
      const answer = postcall?.postcall_answers?.find((pa) => {
        return pa.postcall_question_id.toString() === key
      })
      return { id: answer?.id, postcall_question_id: key, answer: answers[key] }
    })

    dispatch(editPostcall({ jobRouteId, id: postcall.id, postcall: data }))
  }

  return (
    !loading && (
      <>
        <div className="flex justify-between items-center border-b pb-2">
          <h4 className="text-lg font-semibold capitalize">
            Postcalls
          </h4>
        </div>
        <FormSubmissionResult
            successText={`Saved successfully`}
            success={savedAnswered}
            error={error}
          />
        <TosForm onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">
          {questions && questions.map((question, index) => (
            <div key={index} className="mb-6">
              <ul className="mt-4">
                <li className='text-sm text-gray-500'>
                  {question.question}
                  <ul className='items-center flex space-x-2'>
                    <li>
                      <div className="flex items-center mr-2">
                        <input
                          id={`answer-${index}-unanswered`}
                          type="radio"
                          value="0"
                          name={`answer-${index}`}
                          className="w-4 h-4 border border-gray-300 rounded-sm shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          checked={answers[question.id] === "0"}
                          onChange={(e) => handleAnswer(e, question.id)}
                        />
                        <label htmlFor={`answer-${index}-unanswered`} className="ms-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                          Unanswered
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center mr-2">
                        <input
                          id={`answer-${index}-yes`}
                          type="radio"
                          value="1"
                          name={`answer-${index}`}
                          className="w-4 h-4 border border-gray-300 rounded-sm shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          checked={answers[question.id] === "1"}
                          onChange={(e) => handleAnswer(e, question.id)}
                        />
                        <label htmlFor={`answer-${index}-yes`} className="ms-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                          Yes
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <input
                          id={`answer-${index}-no`}
                          type="radio"
                          value="2"
                          name={`answer-${index}`}
                          className="w-4 h-4 border border-gray-300 rounded-sm shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          checked={answers[question.id] === "2"}
                          onChange={(e) => handleAnswer(e, question.id)}
                        />
                        <label htmlFor={`answer-${index}-no`} className="ms-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                          No
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          ))}

          <div>
            <button
              type="submit"
              className="w-20 bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save
            </button>
          </div>
        </TosForm>
        <div className="flex justify-between items-center border-b pb-2 mt-5">
          <h4 className="text-lg font-semibold capitalize">
            Postcall Notes
          </h4>
            <button
              onClick={() => handleAddNote()}
              className="bg-transparent hover:bg-blue-500 text-xs text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            >
              Add Note
            </button>
        </div>
        <ul className="mt-4">
          {postcall && postcall.postcall_notes && postcall.postcall_notes.map((note) => (
              <li key={note.id} className="mb-2">
                <p className="text-xs">{moment(note.created_at).format('LLL')} {note.user_name}</p>
                <p className="text-xs">{note.note}</p>
                <button
                  onClick={() => handleEditNote(note)}
                  className="text-xs text-white font-bold py-0 mr-1"
                >
                  <svg className="h-4 w-4 text-blue-500"  viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                    <line x1="16" y1="5" x2="19" y2="8" />
                  </svg>
                </button>
                <button
                  onClick={() => handleDeleteNote(note)}
                  className="text-xs text-white font-bold"
                >
                  <svg className="h-4 w-4 text-red-500"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <line x1="4" y1="7" x2="20" y2="7" />
                    <line x1="10" y1="11" x2="10" y2="17" />
                    <line x1="14" y1="11" x2="14" y2="17" />
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                  </svg>
                </button>
              </li>
            ))}
          </ul>

          <PostcallNoteModal
            isOpen={showModal}
            onClose={handleCloseModal}
            existingNote={existingNote}
            jobRouteId={jobRoute.id}
            id={postcall?.id}
            loading={loading}
        />
      </>
    )
  );
};

export default Postcall;