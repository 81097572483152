import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchArea, setSuccess, setError } from 'features/areas/areasSlice';
import HeaderTitleBar from 'components/HeaderTitleBar';

const ViewArea = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { areaId } = useParams();
  const {
    areaResource,
    loading,
    error } = useSelector((state) => state.areas);

  useEffect(() => {
    dispatch(fetchArea(areaId));
  }, [dispatch, areaId]);

  setSuccess(false)
  setError(false)

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleBack = () => {
    navigate('/areas');
  };

  return (
    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={`Area Details: ${areaResource?.name || ''}`}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      {(loading || areaResource === null) ? (
        <div>Loading...</div>
      ) : error ? (
        <div className="text-red-500">Error loading Area details.</div>
      ) : (

          <div className="mt-6 w-1/2">
            <div className="mt-6 w-full mb-5">
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Area</p>
                  <p>{areaResource?.area_name || 'N/A'}</p>
                </div>
              </div>
            </div>

          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <p className="font-bold">Address :</p>
              <p>{areaResource.address_1 || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">Address (continued):</p>
              <p>{areaResource.address_2 || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">City:</p>
              <p>{areaResource.city || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">State:</p>
              <p>{areaResource.state_name || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">Zip:</p>
              <p>{areaResource.zip || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">Warehouse:</p>
              <p>{areaResource.warehouse.toString() || 'false'}</p>
            </div>
          </div>

          <div className="mt-6">
            <button
              onClick={() => navigate(`/areas/${areaId}/edit`)}
              className="bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Edit Area
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewArea;

