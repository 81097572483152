import React, { Component } from "react";

class LabelVElement extends Component {

  render() {
    const { name, label } = this.props;
    return (
      <div className="md:w-1/3">
        <label className="block text-sm font-medium text-gray-700 whitespace-nowrap" htmlFor={name}>
          {label}
        </label>
      </div>
    )
  }
}

class LabelHElement extends Component {

  render() {
    const { name, label } = this.props;
    return (
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 whitespace-nowrap">
        {label}
      </label>
    )
  }
}

class ErrorBox extends Component {

  render() {
    const { name, formErrors } = this.props;
    if (!formErrors || typeof formErrors[name] === 'undefined') {
      return null;
    }

    return (
      <p className="text-red-500 text-sm mt-1 whitespace-nowrap">{formErrors[name]}</p>
    );
  }
}


export { LabelVElement, LabelHElement, ErrorBox };