import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormTextInput from 'components/formHelpers/FormTextInput';
import FormStatesSelectBox from 'components/formHelpers/FormStatesSelectBox';
import FormSubmissionResult from 'components/formHelpers/FormSubmissionResult';
import FormSelectBox from 'components/formHelpers/FormSelectBox';
import { editUser } from 'features/users/usersSlice';
import FormDatePicker from 'components/formHelpers/FormDatePicker';
import VendorSelectBox from 'components/dropdowns/VendorSelectBox';
import 'tailwindcss/tailwind.css';
import { fetchUser, setUserSaved } from 'features/users/usersSlice';
import { fetchUserEnums } from "features/users/usersSlice";
import moment from 'moment-timezone';

const EditUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address_1: '',
    address_2: '',
    city: '',
    state_id: 0,
    zip: '',
    user_type: 0,
    role: 0,
    status: 0,
    company_id: 0,
    area_id: 0,
    password: '',
    password_confirmation: '',
    payroll_record_attributes: {
      id: 0,
      hire_date: new Date(),
      vendor_id: 0,
      hourly_rate: 0,
      pay_week_offset: 0,
      external_payroll_id: '',
      pay_rate: 0,
      weekly_salary: 0,
      tax_form: 0,
    }
  });

  const [formErrors, setFormErrors] = useState({});
  const { user_types, roles, statuses, userSaved } = useSelector((state) => state.users);
  const [showFor1099, setShowFor1099] = useState(false);

  const fetchEnumData = useCallback(() => {
    dispatch(fetchUserEnums());
  }, [dispatch]);

  useEffect(() => {
    fetchEnumData();
  }, [fetchEnumData]);

  const { userResource, loading, error, success } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (userSaved === false) return;
    dispatch(setUserSaved(false));
    navigate(`/users/${userResource.id}`)
  }, [navigate, dispatch, userSaved, userResource])

  useEffect(() => {
    if (userResource) {

      let hiringDate = '';
      if (userResource?.payroll_record?.hire_date) {
        hiringDate = userResource?.payroll_record?.hire_date
        hiringDate = new Date(hiringDate)
      }
      if (userResource?.payroll_record?.tax_form == 1) {
        setShowFor1099(true)
      }
      setFormData({
        first_name: userResource.first_name || '',
        last_name: userResource.last_name || '',
        email: userResource.email || '',
        phone_number: userResource.phone_number || '',
        address_1: userResource.address_1 || '',
        address_2: userResource.address_2 || '',
        city: userResource.city || '',
        state_id: userResource?.state?.id || 0,
        zip: userResource.zip || '',
        user_type: userResource.user_type ,
        role: userResource.role ,
        status: userResource.status,
        company_id: userResource?.company?.id,
        area_id: userResource?.area?.id,
        password: null,
        password_confirmation: null,
        payroll_record_attributes: {
          id: userResource?.payroll_record?.id,
          hire_date: hiringDate,
          vendor_id:  userResource?.payroll_record?.vendor_id,
          hourly_rate:  userResource?.payroll_record?.hourly_rate,
          pay_week_offset:  userResource?.payroll_record?.pay_week_offset,
          external_payroll_id:  userResource?.payroll_record?.external_payroll_id,
          pay_rate:  userResource?.payroll_record?.pay_rate,
          weekly_salary:  userResource?.payroll_record?.weekly_salary,
          tax_form:  (userResource?.payroll_record?.tax_form === 'consultant') ? true : false
        }
      });
    }

  }, [userResource]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.first_name) {
      newErrors.first_name = 'First Name is required';
    }

    if (!formData.last_name) {
      newErrors.last_name = 'Last Name is required';
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
    }

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    const newData = formData;
    if (name === 'user_type' || name === 'status' || name === 'role') {
      if (value === 0) {
        value = null;
      }
    }

    newData[name] = value;
    setFormData(newData);
  };

  const handlePayRollChange = (e) => {
    const { name, value, checked } = (e.target) ? e.target : e;

    const newData = { ...formData };
    if (name === 'tax_form') {
      newData.payroll_record_attributes[name] = checked ? 1 : 0;
    } else {
      newData.payroll_record_attributes[name] = value;
    }

    setFormData(newData);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors({});

    if (validateForm()) {
      const scrubbedData = Object.assign({}, formData);
      const scrubbedPayRollData = Object.assign({}, scrubbedData.payroll_record_attributes);

      if (scrubbedPayRollData.tax_form === "on") {
        scrubbedPayRollData.tax_form = 1
      }

      let date = null;
      try {
        date = scrubbedPayRollData?.hire_date?.toLocaleDateString('en-ca');
      } catch (error) {
        date = scrubbedPayRollData?.hire_date;
      }

      const newDate = moment(date).format('YYYY/MM/DD')
      scrubbedPayRollData.hire_date = newDate;
      scrubbedData.payroll_record_attributes = scrubbedPayRollData

      // console.log(scrubbedData)
      // return
      dispatch(editUser({ userId, formData: scrubbedData, navigate }));
    }
  };

  const handleBack = () => {
    navigate(`/users/${userId}`);
  };

  return (
    <div className="p-4 mb-6 bg-white shadow rounded-lg">

      <HeaderTitleBar
        title={`Edit User: ${formData?.first_name} ${formData?.last_name}`}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      <FormSubmissionResult
        successText={`User "${formData?.first_name} ${formData?.last_name}" Updated`}
        success={success}
        error={error}
      />

      {(loading || userResource === null) ? (
        <div>Loading...</div>
      ) : error ? (
        <div className="text-red-500">Error loading user details.</div>
      ) : (

        <form onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">
          <div className="mt-6 w-full">
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div className='flex gap-2 '>
                <p className="font-semibold">Company</p>
                <p>{userResource?.company?.name || 'N/A'}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div className='flex gap-2 '>
                <p className="font-semibold">Area</p>
                <p>{userResource?.area?.name || 'N/A'}</p>
              </div>
            </div>
          </div>

        <div className="grid justift-items-start grid-cols-2 gap-8">
          <FormTextInput
            label={'First Name'}
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="Enter First Name"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Last Name'}
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Enter Last Name"
            formErrors={formErrors}
          />
        </div>

        <div className="grid justift-items-start grid-cols-2 gap-8">
          <FormTextInput
            label={'Email'}
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter Email"
            formErrors={formErrors}
            autoComplete={'on'}
          />

          <FormTextInput
            label={'Phone Number'}
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            placeholder="Enter Phone Number"
            formErrors={formErrors}
          />
        </div>

        <FormTextInput
          label={'Address 1'}
          name="address_1"
          value={formData.address_1}
          onChange={handleChange}
          placeholder="Enter Address"
          formErrors={formErrors}
        />

        <FormTextInput
          label={'Address (continued)'}
          name="address_2"
          value={formData.address_2}
          onChange={handleChange}
          placeholder="Enter Address (continued)"
          formErrors={formErrors}
        />

        <div className="grid justift-items-start grid-cols-3 gap-8">
          <FormTextInput
            label={'City'}
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="Enter City"
            formErrors={formErrors}
          />

          <FormStatesSelectBox
            state_id={formData.state_id}
            required={false}
            formErrors={formErrors}
            onChange={handleChange}
          />

          <FormTextInput
            label={'Zip'}
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            placeholder="Enter Zip Code"
            formErrors={formErrors}
          />
        </div>

        <div className="grid justift-items-start grid-cols-3 gap-8">
          <FormSelectBox
            label={'User Type'}
            name="user_type"
            defaultValue={formData.user_type}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a User Type'}
            onChange={handleChange}
            formErrors={formErrors}
            data={user_types}
          />

          <FormSelectBox
            label={'Role'}
            name="role"
            defaultValue={formData.role}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a Role'}
            onChange={handleChange}
            formErrors={formErrors}
            data={roles}
          />

          <FormSelectBox
            label={'Status'}
            name="status"
            defaultValue={formData.status}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a Status'}
            onChange={handleChange}
            formErrors={formErrors}
            data={statuses}
          />
        </div>
        <h3 className='text-x1 text-gray-800 text-semibold pt-4'>Pay Roll Information</h3>
          <div className="grid justift-items-start grid-cols-2 gap-2">
              <FormDatePicker
                label={'Hire Date'}
                name="hire_date"
                onChange={(e) => handlePayRollChange({ name: 'hire_date', value: e })}
                placeholder="Select a Hire Date"
                value={formData.payroll_record_attributes.hire_date}
                horizontal={false}
              />

              <VendorSelectBox
                label={'Vendor'}
                name={'vendor_id'}
                value={formData.payroll_record_attributes.vendor_id}
                onChange={handlePayRollChange}
                useTarget={false}
              />
          </div>

          <div className="grid justift-items-start grid-cols-2 gap-2">
              <FormTextInput
                label={'Pay Rate %'}
                name="pay_rate"
                value={formData.payroll_record_attributes.pay_rate}
                onChange={handlePayRollChange}
                placeholder="Enter a Pay Rate (%)"
                disabled={showFor1099}
                formErrors={formErrors}
                autoComplete={'off'}
              />

              <FormTextInput
                label={'Payweek Offset'}
                name="pay_week_offset"
                value={formData.payroll_record_attributes.pay_week_offset}
                onChange={handlePayRollChange}
                placeholder="Enter a Weekly offset"
                formErrors={formErrors}
                autoComplete={'off'}
              />
          </div>


          <div className="grid justift-items-start grid-cols-2 gap-2">
              <FormTextInput
                label={'Hourly Rate'}
                name="hourly_rate"
                value={formData.payroll_record_attributes.hourly_rate}
                onChange={handlePayRollChange}
                placeholder="Enter a Hourly Rate"
                formErrors={formErrors}
                disabled={!showFor1099}
                autoComplete={'off'}
              />

              <FormTextInput
                label={`Weekly Salary`}
                name="weekly_salary"
                value={formData.payroll_record_attributes.weekly_salary}
                onChange={handlePayRollChange}
                placeholder="Enter a Weekly Salary"
                formErrors={formErrors}
                disabled={!showFor1099}
                autoComplete={'off'}
              />
          </div>

          <div className="grid justift-items-start grid-cols-2 gap-2">
              <FormTextInput
                label={'Payroll Id'}
                name="external_payroll_id"
                value={formData.payroll_record_attributes.external_payroll_id}
                onChange={handlePayRollChange}
                placeholder="Enter a Payroll Id"
                formErrors={formErrors}
                autoComplete={'off'}
              />
              <div className="flex items-center mt-5">
                <input
                  id="tax_form"
                  name="tax_form"
                  type="checkbox"
                  defaultChecked={formData.payroll_record_attributes.tax_form}
                  onChange={(e) => {
                    setShowFor1099(e.target.checked);
                    handlePayRollChange(e);
                  }}
                  className="border border-gray-300 ml-3 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />

                <label htmlFor="tax_form" className="ms-2 text-sm font-medium text-gray-700">1099</label>
              </div>
          </div>

          <div className="grid justift-items-start grid-cols-2 gap-8 pt-10">
            <FormTextInput
              label={'Password'}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter a Password"
              formErrors={formErrors}
              autoComplete='new-password'
              typeOverride="password"
            />

            <FormTextInput
              label={'Password Confirmation'}
              name="password_confirmation"
              value={formData.password_confirmation}
              onChange={handleChange}
              placeholder="Confirm your Password"
              formErrors={formErrors}
              autoComplete='new-password'
              typeOverride="password"
            />
          </div>
        <div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Update User
          </button>
        </div>
      </form>

      )}
    </div>
  );
};

export default EditUser;
