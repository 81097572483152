import React, { Component } from "react";

class TosForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <form {...this.props} >
        { this.props.children }
      </form>
    )
  }
}

export default TosForm;