import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import FormTextInput from 'components/formHelpers/FormTextInput';
import TosForm from 'components/formHelpers/TosForm';
import { addTechnician } from 'features/users/usersSlice';

const AddTechnicianModal = ({ isOpen, onClose, userId }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    tech_id: '',
    area_id: '',
    pay_rate: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { technicians } = useSelector((state) => state.users);


  const handleAreaChange = (e) => {
    const { value, label } = e.target;
    setSelectedAreaId(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      area_id: value,
    }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      await dispatch(addTechnician({ userId, techData: formData })).unwrap();
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-full max-w-md">
          <h3 className="text-lg font-semibold mb-4">
            Add Tech ID to User
          </h3>
          <TosForm onSubmit={handleSubmit}>
            <div className="mb-4">
              <FormTextInput
                label={'Tech ID'}
                name="tech_id"
                value={formData.tech_id}
                onChange={handleChange}
                placeholder="Tech Id"
                formErrors={formErrors}
              />
              <AreaSelectBox
                label={'Area'}
                name={'area_id'}
                value={selectedAreaId}
                onChange={handleAreaChange}
                useTarget={false}
              />
              <FormTextInput
                label={'Pay Rate'}
                name="pay_rate"
                value={formData.pay_rate}
                onChange={handleChange}
                placeholder="Pay Rate"
                formErrors={formErrors}
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-black rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Save
              </button>
            </div>
          </TosForm>
        </div>
      </div>
    )
  )
};

export default AddTechnicianModal;