import React, { Component } from 'react'
import 'tailwindcss/tailwind.css';
import Select from 'react-select'

class SelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue || "0",
    };
  }

  handleChange = (selectedValue) => {
    const { value } = selectedValue;
    this.setState({ value });
    this.props.onChange(value);
  }

  render() {
    const {
      data,
      valueField,
      keyField,
      zeroOption
    } = this.props;

    let options = [{ value: "0", label: zeroOption }];

    if (data?.length) {
      data.forEach((item) => {
        options.push({ value: item[valueField], label: item[keyField] });
      });
    }

    return (
      <Select
        value={options.find(opt => opt.value === this.state.value)}
        onChange={this.handleChange}
        options={options}
        className="mt-1 block w-full p-2 rounded-xs focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    );
  }
}

export default SelectBox;
