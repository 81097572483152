import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

export const fetchPostcall = createAsyncThunk(
  'postcalls/fetchPostcall',
  async ({ jobRouteId, id }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/postcall`;
      const response = await fetchWithAuth(url);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const editPostcall = createAsyncThunk(
  'postcalls/editPostcall',
    async ({ jobRouteId, id, postcall }, { rejectWithValue }) => {
      const msoId = localStorage.getItem('msoId');

      try {
        const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/postcalls/${id}`;
        const response = await fetchWithAuth(url, {
          method: 'PUT',
          body: JSON.stringify({ postcall }),
        });

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const fetchQuestions = createAsyncThunk(
  'postcalls/fetchQuestions',
  async ({ jobRouteId }) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/postcalls/questions/${jobRouteId}`;
      const response = await fetchWithAuth(url);

      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const savePostcallNote = createAsyncThunk(
  'postcalls/savePostcallNote',
    async ({ jobRouteId, id, postcallNote, existingNote }, { rejectWithValue }) => {
      const msoId = localStorage.getItem('msoId');

      let url = '';
      let response = null;

      try {
        if (existingNote) {
          url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/postcalls/${id}/postcall_notes/${existingNote.id}`;
          response = await fetchWithAuth(url, {
            method: 'PUT',
            body: JSON.stringify({ postcall_note: postcallNote }),
          });
        } else {
          url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/postcalls/${id}/postcall_notes`;
          response = await fetchWithAuth(url, {
            method: 'POST',
            body: JSON.stringify({ postcall_note: postcallNote }),
          });
        }
        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const deletePostcallNote = createAsyncThunk(
    'postcalls/deletePostcallNote',
    async ({ jobRouteId, postcallId, noteId }, { rejectWithValue }) => {
      const msoId = localStorage.getItem('msoId');

      try {
        const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/job_routes/${jobRouteId}/postcalls/${postcallId}/postcall_notes/${noteId}`;
        await fetchWithAuth(url, {
          method: 'DELETE'
        });

        return noteId;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );



const postcallsSlice = createSlice({
  name: 'jobRouteNotes',
  initialState: {
    notes: [],
    id: null,
    loading: false,
    savedAnswered: false,
    error: null,
    postcall: null,
    questions: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearNotes: (state) => {
      state.notes = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPostcall.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchPostcall.fulfilled, (state, action) => {
      state.loading = false;
      state.postcall = action.payload;
      state.id = action.payload.id
    })
    .addCase(fetchPostcall.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.postcall = {};
    })
    .addCase(fetchQuestions.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchQuestions.fulfilled, (state, action) => {
      state.loading = false;
      state.questions = action.payload;
    })
    .addCase(fetchQuestions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.questions = [];
    })

    .addCase(editPostcall.pending, (state) => {
      state.loading = true;
      state.savedAnswered = false;
    })
    .addCase(editPostcall.fulfilled, (state, action) => {
      state.loading = false;
      state.postcall = action.payload;
      state.savedAnswered = true
    })
    .addCase(editPostcall.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.savedAnswered = false;
    })

    .addCase(savePostcallNote.pending, (state) => {
      state.loading = true;
    })
    .addCase(savePostcallNote.fulfilled, (state, action) => {
      state.loading = false;
      state.postcall = action.payload;
      state.id = action.payload.id;
    })
    .addCase(savePostcallNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    .addCase(deletePostcallNote.pending, (state) => {
      state.loading = true;
    })
    .addCase(deletePostcallNote.fulfilled, (state, action) => {
      state.loading = false;
      state.postcall = action.payload;
    })
    .addCase(deletePostcallNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { setLoading, setError, clearNotes } = postcallsSlice.actions;
export default postcallsSlice.reducer;