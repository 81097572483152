import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import JobRoutesDataTable from 'components/JobRoutesDataTable';
import { selectAuthUser } from '../../features/auth/authUserSlice';
import 'tailwindcss/tailwind.css';
import HeaderTitleBar from 'components/HeaderTitleBar';
import { fetchCompanies } from '../../features/companies/companiesSlice';
import SelectBox from '../fields/SelectBox';
import { titlize } from 'utils/TextTools';

import {
  fetchJobRoutes,
  clearJobRoutes,
  clearData,
  setPageSize,
  setSelectedCompanyId,
  setSearchQuery
} from '../../features/jobRoutes/jobRouteFilterSlice';

const Jobroutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authUser = useSelector((state) => state.authUser);
  const authUserRole = authUser.role;
  const [postcallType, setPostcallType] = useState('0');

  const StatusText = ({ field, row }) => {
    return titlize(row.original[field]);
  }

  const DateAdapter = ({ field, row }) => {
    if (row.original[field] === null) return;
    return row.original[field].toString();
  }

  const HhcAdapter = ({ field, row }) => {
    if (row.original[field] === null) {
      return 'pending';
    }

    if (row.original[field] === false) {
      return 'failed'
    }

    return 'passed';
  }

  const JobLink = ({ field, row }) => {
    return <Link
      to={`/billing/job_details/${row.original.id}`}
      onClick={() => { navigate( `/technicians/${row.original.id}` ) }}
      className="font-medium underline">
        {row.original[field]}
      </Link>;
  }

  const columns = useMemo(() => {
    if (localStorage.getItem('msoId').toString() == '4') {
      return [
        { header: 'Tech ID', accessorKey: 'tech_id' },
        { header: 'Technician', accessorKey: 'full_name' },
        {
          header: 'Job Date',
          accessorKey: 'job_date',
          cell: ({ getValue, row}) => <DateAdapter field={'job_date'} value={getValue()} row={row} />
        },
        {
          header: 'Account Number',
          accessorKey: 'account_number',
          cell: ({ getValue, row }) => <JobLink field={'account_number'} value={getValue()} row={row} />
        },
        { header: 'Area', accessorKey: 'area_name' },
        { header: 'Job Number', accessorKey: 'job_number' },
        {
          header: 'Status',
          accessorKey: 'status',
          cell: ({ getValue, row }) => <StatusText field={'status'} value={getValue()} row={row} />
        },
        { header: 'Time', accessorKey: 'time_frame' },
        { header: 'Job Type', accessorKey: 'job_type' },
        {
          header: 'HHC',
          accessorKey: 'passed',
          cell: ({ getValue, row }) => <HhcAdapter field={'passed'} value={getValue()} row={row} />
        },
        { header: 'Postcall', accessorKey: 'postcall_complete' },
      ]
    } else {
      return [
        {
          header: 'Job Number',
          accessorKey: 'job_number',
          cell: ({ getValue, row }) => <JobLink field={'job_number'} value={getValue()} row={row} />
        },
        { header: 'Technician', accessorKey: 'user.full_name' },
        {
          header: 'Status',
          accessorKey: 'status',
          cell: ({ getValue, row }) => <StatusText field={'status'} value={getValue()} row={row} />
        },
        { header: 'Area', accessorKey: 'area.name' },
        { header: 'Tech ID', accessorKey: 'tech_id' },
        { header: 'Account Number', accessorKey: 'account_number' },
        { header: 'Job Type', accessorKey: 'job_type' },
        { header: 'Job Date', accessorKey: 'job_date' },
      ]
    }
  }, []);

  const dispatch = useDispatch();

  const {
    data,
    loading,
    pageCount,
    totalItems,
    pageSize,
    selectedCompanyId,
    selectedAreaId,
    selectedUserId,
    searchQuery
  } = useSelector((state) => state.jobRouteFilter);

  const { companyCollection } = useSelector((state) => state.companies);
  const [pageIndex, setPageIndex] = useState(0);
  const [sorting, setSorting] = useState([{ id: 'job_date', desc: true, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  const canSelectCompany = () => {
    return /admin|mso_user/.test(authUserRole);
  }

  const fetchCompaniesData = useCallback(() => {
    dispatch(fetchCompanies({ page: 1, per: 9999, navigate }));
  }, [ dispatch, navigate]);

  useEffect(() => {
    if (!canSelectCompany()) return;

    fetchCompaniesData();
  }, [ fetchCompaniesData, dispatch ])

  const handleSelectedCompanyId = (value) => {
    /* TODO - REMOVE THIS LATER
    */
    localStorage.setItem('companyId', value);
    dispatch(setSelectedCompanyId(value));
  };

  const fetchJobRoutesData = useCallback(() => {
    dispatch(fetchJobRoutes({
      companyId: selectedCompanyId ,
      areaId: selectedAreaId,
      userId: selectedUserId,
      page: pageIndex + 1,
      per: pageSize,
      search: searchQuery,
      postcall: postcallType,
      sorting
    }));
  }, [dispatch, location.pathname, selectedCompanyId, selectedAreaId, selectedUserId, pageIndex, pageSize, searchQuery, postcallType, sorting]);

  useEffect(() => {
    dispatch(clearJobRoutes())
  }, [location.pathname])

  useEffect(() => {
    let otherCompany = selectedCompanyId;
    if (!canSelectCompany()) {
      dispatch(setSelectedCompanyId(authUser.company_id));
      otherCompany = authUser.company_id
    }

    const canViewWithoutCompany = (authUser.role == 'administrator' || authUser.role == 'mso_user')
    if (canViewWithoutCompany) {
      fetchJobRoutesData()
      return;
    }

    if (otherCompany === null) return;

    console.log('otherCompany', otherCompany)
    if (!otherCompany) {
      dispatch(clearJobRoutes());
    } else {
      fetchJobRoutesData();
    }
  }, [selectedCompanyId, fetchJobRoutesData, dispatch]);

  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    dispatch(setSearchQuery(newSearchQuery));
    setPageIndex(0);
  };

  const handlePostcallType = (e) => {
    const selectedPostcallType = e.target.value;
    setPostcallType(selectedPostcallType)
  };

  const ToolBox = () => {

    return (
      <>
        <ul className="flex space-x-2 mb-1 mt-2">
          <li>
            <span className='text-gray-800 text-sm'>Postcalls</span>
          </li>
          <li className='text-gray-800 text-sm'>
            <input
              id={`postcall_complete`}
              type="radio"
              value="0"
              name={`postcall_complete`}
              className="w-4 h-4 border border-gray-300 rounded-sm shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              checked={postcallType === '0'}
              onChange={(e) => handlePostcallType(e)}
            />
            <label htmlFor={`postcall_complete`} className="ms-2 text-sm font-medium text-gray-500 whitespace-nowrap">
              All
            </label>
          </li>
          <li className='text-gray-800 text-sm'>
            <input
              id={`postcall_complete`}
              type="radio"
              value="1"
              name={`postcall_complete`}
              className="w-4 h-4 border border-gray-300 rounded-sm shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              checked={postcallType === '1'}
              onChange={(e) => handlePostcallType(e)}
            />
            <label htmlFor={`postcall_complete`} className="ms-2 text-sm font-medium text-gray-500 whitespace-nowrap">
              Completed
            </label>
          </li>
          <li className='text-gray-800 text-sm'>
            <input
              id={`postcall_complete`}
              type="radio"
              value="2"
              name={`postcall_complete`}
              className="w-4 h-4 border border-gray-300 rounded-sm shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              checked={postcallType === '2'}
              onChange={(e) => handlePostcallType(e)}
            />
            <label htmlFor={`postcall_complete`} className="ms-2 text-sm font-medium text-gray-500 whitespace-nowrap">
              Incomplete
            </label>
          </li>
        </ul>
      </>
    )
  };

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Job Routes'
          showBtn={false}
        />
        {canSelectCompany() && (
          <div className="mt-4">
            <SelectBox
              data={companyCollection}
              defaultValue={selectedCompanyId}
              onChange={handleSelectedCompanyId}
              valueField={'id'}
              keyField={'name'}
              zeroOption={'Select a Company (All)'}
            />
        </div>
        )}
      </div>

      <JobRoutesDataTable
        columns={columns}
        data={data}
        pageCount={pageCount}
        totalItems={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
        onSortingChange={handleSortingChange}
        onSearchChange={handleSearchChange}
        toolbox={<ToolBox />}
      />
    </>
  );
};

export default Jobroutes;