import React, { Component } from "react";
import { LabelHElement, LabelVElement, ErrorBox } from './FormShared';
import Select from 'react-select';

class FormSelectBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: props.defaultValue || "0"
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ selectedValue: this.props.defaultValue || "0" });
    }

    if (prevProps.data !== this.props.data && this.props.useDefault && !this.props.defaultValue) {
      const defaultItem = this.props.data?.find((item) => item.default);
      if (defaultItem) {
        this.setState({ selectedValue: defaultItem[this.props.valueField] });
      }
    }
  }

  handleChange = (event) => {
    const { label, value } = event;
    const tempObj = {
      target: {
        name: this.props.name,
        value: value,
        label: label
      }
    };
    this.setState({ selectedValue: value });
    this.props.onChange(tempObj);
  }

  selectOptions = () => {
    const {
      loadingState,
      zeroOption = "Select an option",
      data,
      valueField,
      keyField
    } = this.props;

    if (loadingState) {
      return [{ value: "-1", label: "Loading..." }];
    }

    let options = [{ value: "0", label: zeroOption }];

    if (data?.length) {
      data.forEach((item) => {
        options.push({ value: item[valueField], label: item[keyField] });
      });
    }

    return options;
  }

  renderInput = () => {
    const { name, keyName, required } = this.props;
    const { selectedValue } = this.state;

    return (
      <Select
        name={name}
        id={name}
        key={keyName}
        value={this.selectOptions().find(opt => opt.value.toString() === selectedValue.toString())}
        onChange={this.handleChange}
        required={required}
        options={this.selectOptions()}
        isSearchable={this.props.isSearchable || true}
        className="mt-1 block w-full rounded-xs focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    );
  }

  render() {
    const { horizontal, name, label, formErrors } = this.props;

    return horizontal ? (
      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3"><LabelHElement name={name} label={label}/></div>
        <div className="md:w-2/3">
          {this.renderInput()}
          <ErrorBox name={name} formErrors={formErrors} />
        </div>
      </div>
    ) : (
      <div>
        <LabelVElement name={name} label={label}/>
        {this.renderInput()}
        <ErrorBox name={name} formErrors={formErrors} />
      </div>
    );
  }
}

export default FormSelectBox;
