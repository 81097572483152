import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthToken } from '../features/auth/authSlice';
import { setAuthUser } from '../features/auth/authUserSlice';
import { selectSubdomain } from '../features/subdomain/subdomainSlice';
import { useNavigate } from 'react-router-dom';
import { setSubdomain } from '../features/subdomain/subdomainSlice';
import { getSubDomain } from 'utils/NetworkTools';

const Login = ({authorizationError = ''}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(authorizationError);

  const [subdomain, setSubdomain] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getSubDomain();
  }, [ subdomain ])

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: { email, password }}),
      });

      const data = await response.json();

      if (data.token && data.token.access_token) {
        dispatch(setAuthToken(data.token.access_token));
        dispatch(setAuthUser(data.data));

        if (data.data.user_type === 'admin') {
          const msoName = 'Admin';
          dispatch(setSubdomain(msoName));
        } else {
          const msoName = data.data.mso.name.toLowerCase();
          console.log('msoName', msoName)
          localStorage.setItem('msoId', data.data.mso.id);
          localStorage.setItem('msoName', msoName);
          localStorage.setItem('subdomain', msoName);
          dispatch(setSubdomain(msoName));
        }

        navigate('/dashboard');
      } else {
        setError(data.error || 'Login failed. Please try again.');
      }
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        setError('Error communicating with the API')
      } else {
        setError(error.message);
      }
    }
  };


  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl pb-6 font-semibold text-center text-gray-900 border-b-2">
        TOS Dashboard
      </h2>
      <form className="mt-8 space-y-6" onSubmit={handleLogin}>
        {error && (
          <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
            {error}
          </div>
        )}
        <div className="rounded-md shadow-sm">
          <div>
            <label htmlFor="email-address" className="sr-only">Email address</label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="password" className="sr-only">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md group hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
  );
};

export default Login;