import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTitleBar from '../HeaderTitleBar';
import InventoryHistoryModal from './components/modals/InventoryHistoryModal';
import AddInventoryModal from './components/modals/AddInventoryModal';
import IssueInventoryModal from './components/modals/IssueInventoryModal';
import ReturnInventoryModal from './components/modals/ReturnInventoryModal';
import TransferInventoryModal from './components/modals/TransferInventoryModal';
import EditAccountNumberModal from './components/modals/EditAccountNumberModal';

import FormSelectBox from 'components/formHelpers/FormSelectBox';

import 'tailwindcss/tailwind.css';

import { fetchMsos } from '../../features/msos/msosSlice';
import {
  fetchInventories,
  exportInventories,
  setPageSize,
  setSearchQuery
} from '../../features/inventories/inventorySlice';

const Inventory = () => {
  const navigate = useNavigate();
  const dateColumns = {
    'Received on': 'date_received',
    'Issued on': 'date_issued',
    'Installed on': 'date_installed',
  }

  const dataColumnIds = {
    'date_received': 1,
    'date_received': 5,
    'date_issued': 2,
    'date_installed': 8
  }

  const ActionStatus = (data) => {
    return data.row.action_status;
  }

  const DateTable = (data) => {
    return (
      <ul key={data.row.id}>
        {Object.keys(dateColumns).map((key) => {
          const value = dateColumns[key]

          if (data.row[value] && data.row.action_status_id === dataColumnIds[value]) {
            return (
              <li key={Date.now() + Math.random()}>{key}: {data.row[value]}</li>
            )
          }
        })}
      </ul>
    )
  }

  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showAddInventoryModal, setShowAddInventoryModal] = useState(false);
  const [showIssueInventoryModal, setShowIssueInventoryModal] = useState(false);
  const [showReturnInventoryModal, setShowReturnInventoryModal] = useState(false);
  const [showTransferInventoryModal, setShowTransferInventoryModal] = useState(false);
  const [showAccountNumberyModal, setShowAccountNumberModal] = useState(false);
  const [actionStatus, setActionStatus] = useState('0')
  const [currentId, setCurrentId] = useState(null);

  const [inventory, setInventory] = useState(null);
  const handleCloseHistoryModel = () => {
    setShowHistoryModal(false);
  }
  const handleCloseAddInventoryModal = () => {
    setShowAddInventoryModal(false)
  }

  const handleCloseIssueInventoryModal = () => {
    setShowIssueInventoryModal(false)
  }

  const handleCloseReturnInventoryModal = () => {
    setShowReturnInventoryModal(false)
  }

  const handleCloseTransferInventoryModal = () => {
    setShowTransferInventoryModal(false)
  }

  const handleCloswAccountNumberModal = () => {
    setShowAccountNumberModal(false)
  }

  const InventoryHistory = (data) => {
    return <Link
      onClick={() => {
        setInventory(data.row);
        setShowHistoryModal(true);
      }}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
        {data.row['serial_number']}
      </Link>;
  }

  const AccountNumberHandler = (data) => {
    const account_number = data.row['account_number'];
    const action_status_id = data.row['action_status_id']

    if (account_number?.toString() === '' && action_status_id === 2) {
      return <Link
        onClick={() => handleAccountNumberClick(data.row['id'])}
        className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
          edit
      </Link>;
    }

    return data.row['account_number']
  }

  const columns = useMemo(() => [
    {
      header: 'Serial Number',
      accessorKey: 'serial_number',
      component: (row) => <InventoryHistory row={row} />
    },
    { header: 'Inventory Type', accessorKey: 'type_name' },
    {
      header: 'Account Number',
      accessorKey: 'account_number',
      component: (row) => <AccountNumberHandler row={row} /> },
    { header: 'Dates', component: (row) => <DateTable row={row} /> },
    { header: 'Days old', accessorKey: 'days_since' },
    { header: 'Area', accessorKey: 'area_name' },
    { header: 'Tech ID', accessorKey: 'tech_id' },
    { header: 'User', accessorKey: 'full_name' }
  ], []);

  const dispatch = useDispatch();

  const {
    data,
    loading,
    currentPage,
    nextPage,
    prevPage,
    pageCount,
    totalItems,
    pageSize,
    searchQuery,
    errorMessage,
  } = useSelector((state) => state.inventories);

  const authUser = useSelector((state) => state.authUser);
  const [pageIndex, setPageIndex] = useState(0);
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);


  const fetchMsosData = useCallback(() => {
    dispatch(fetchMsos({ page: 1, per: 9999 }));
  }, [dispatch]);

  useEffect(() => {
    fetchMsosData();
  }, [fetchMsosData]);


  const fetchInventoriesData = useCallback(() => {
    dispatch(fetchInventories({
      page: pageIndex + 1,
      per: pageSize,
      search: searchQuery,
      actionStatus,
      sorting
    }));
  }, [dispatch, pageIndex, pageSize, searchQuery, actionStatus, sorting]);


  useEffect(() => {
    fetchInventoriesData();
  }, [ fetchInventoriesData, dispatch ])

  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    dispatch(setSearchQuery(newSearchQuery));
    setPageIndex(0);
  };

  const handleAddClick = () => {
    setShowAddInventoryModal(true);
  }

  const handleIssueClick = () => {
    setShowIssueInventoryModal(true);
  }

  const handleReturnClick = () => {
    setShowReturnInventoryModal(true);
  }

  const handleTransferClick = () => {
    setShowTransferInventoryModal(true);
  }

  const handleAccountNumberClick = (inventoryId) => {
    setCurrentId(inventoryId);
    setShowAccountNumberModal(true);
  }

  const handleExport = () => {
    dispatch(exportInventories({
      search: searchQuery,
      sorting
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setActionStatus(value);
  };

  const renderBody = () => {
    return (
      <div className="p-4 bg-white shadow rounded-lg">
        {errorMessage && (
          <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
            {errorMessage}
          </div>
        )}
        <div className="mb-0">
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => handleSearchChange(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
        <div className='w-1/4'>
          <FormSelectBox
            name='action_status_id'
            data={[
              { id: 1, name: 'In warehouse (Not issued)' },
              { id: 2, name: 'Issued' },
              { id: 3, name: 'In Warehouse (Pending for Refresh)' },
              { id: 4, name: 'Returned to MSO (Defective Equipment)' },
              { id: 5, name: 'In warehouse (received transfer)' },
              { id: 6, name: 'Returned from customer' },
              { id: 7, name: 'Inventory Lost' },
              { id: 8, name: 'Installed' },
              { id: 9, name: 'Invoiced' },
            ]}
            defaultValue={actionStatus}
            onChange={(e) => handleChange(e) }
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select an Action Status (All)'}
          />
        </div>

        {renderTable()}
        <div className="py-3 flex items-center justify-between">
          <button
            onClick={() => handlePageChange(pageIndex - 1, pageSize)}
            disabled={prevPage === null}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {pageCount} ({totalItems} items)
          </span>
          <button
            onClick={() => handlePageChange(pageIndex + 1, pageSize)}
            disabled={nextPage === null}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
          >
            Next
          </button>
          <select
            value={pageSize}
            onChange={(e) => handlePageChange(pageIndex, Number(e.target.value))}
            className="ml-4 px-2 py-1 border rounded"
          >
            {[10, 25, 50].map(size => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  };

  const renderTableBody = () => {
    return (
      <>
        {loading ? (
          <tr key='loading'>
            <td colSpan={columns.length} className="text-center p-4">
              Loading...
            </td>
          </tr>
        ) : (
          data.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <tr>
                {columns.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">
                    {cell.component ? (
                      cell.component(row)
                    ) : (
                      row[cell.accessorKey]
                    )}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))
        )}
      </>
    )
  }

  const renderTable = () => {
    return (
      <table className="min-w-full divide-y divide-gray-200 mt-4 border border-gray-300">
        <thead className="bg-gray-50">
          <tr className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
            {columns.map((col) => {
                const header = col.header;
                const accessorKey = col.accessorKey;
                const isSorted = sorting.find(sort => sort.id === accessorKey);
                const isSortedDesc = isSorted ? isSorted.desc : false;
              return (
                <th
                  key={header}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => {
                    const newSorting = [...sorting];
                    newSorting.forEach(s => s.selected = false )

                    const existing = newSorting.find(sort => sort.id === accessorKey);
                    if (existing) {
                      existing.desc = !existing.desc;
                      existing.selected = true;
                    } else {
                      newSorting.push({ id: accessorKey, desc: false, selected: true });
                    }
                    handleSortingChange(newSorting);
                  }}
                >
                  <div className="flex items-center">
                    {header}
                    {isSorted ? (
                      isSortedDesc ? (
                        <span className="ml-2">🔽</span>
                      ) : (
                        <span className="ml-2">🔼</span>
                      )
                    ) : null}
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
            {renderTableBody()}
        </tbody>
      </table>
    )
  };

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <header className="flex items-center justify-between">
          <h1 className="text-xl font-semi text-gray-900">Inventory</h1>
          {authUser.user_type.match(/admin|corporate|warehouse|supervisor/) && (
          <div className='text-right'>
            <button
              onClick={handleAddClick}
              className="items-right bg-blue-500 text-white font-semi py-1 px-4 rounded-l-sm hover:bg-blue-700">
              Add
            </button>
            <button
              onClick={handleIssueClick}
              className="items-right bg-green-500 text-white font-semi py-1 px-4 hover:bg-green-700">
              Issue
            </button>
            <button
              onClick={handleReturnClick}
              className="items-right bg-sky-500 text-white font-semi py-1 px-4 hover:bg-sky-700">
              Return
            </button>
            <button
              onClick={handleTransferClick}
              className="items-right bg-red-500 text-white font-semi py-1 px-4 rounded-r-sm hover:bg-red-700">
              Transfer
            </button>

            <button
              onClick={handleExport}
              className="items-right bg-black text-white font-semi py-1 px-4 ml-2 rounded-r-sm hover:bg-gray-500">
              Export All
            </button>
          </div>
          )}
        </header>
      </div>

      {renderBody()}

      {showHistoryModal && (
        <InventoryHistoryModal
          isOpen={showHistoryModal}
          onClose={handleCloseHistoryModel}
          inventory={inventory}
        />
      )}

      {showAddInventoryModal && (
        <AddInventoryModal
          isOpen={showAddInventoryModal}
          onClose={handleCloseAddInventoryModal}
          loading={loading}
        />
      )}

      {showIssueInventoryModal && (
        <IssueInventoryModal
          isOpen={showIssueInventoryModal}
          onClose={handleCloseIssueInventoryModal}
          loading={loading}
        />
      )}

      {showReturnInventoryModal && (
        <ReturnInventoryModal
          isOpen={showReturnInventoryModal}
          onClose={handleCloseReturnInventoryModal}
          loading={loading}
        />
      )}

      {showTransferInventoryModal && (
        <TransferInventoryModal
          isOpen={showTransferInventoryModal}
          onClose={handleCloseTransferInventoryModal}
          loading={loading}
        />
      )}

      {showAccountNumberyModal && (
        <EditAccountNumberModal
          isOpen={showAccountNumberyModal}
          onClose={handleCloswAccountNumberModal}
          loading={loading}
          inventoryId={currentId}
        />
      )}
    </>
  );
};

export default Inventory;