import React from 'react';
import HeaderTitleBar from './HeaderTitleBar';

function Welcome() {
  return (
    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={`Welcome to TOS 3`}
        showBtn={false}
      />
    </div>
  );
}

export default Welcome;