import React, { useState, useCallback, useEffect } from "react"
import FormSelectBox from "./FormSelectBox"
import { fetchStates } from "features/states/statesSlice"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const FormStatesSelectBox = ({
    onChange,
    required,
    formErrors,
    state_id,
  }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: stateData } = useSelector((state) => state.states);

  const fetchStatesData = useCallback(() => {
    if (stateData?.length > 0) return;

    dispatch(fetchStates(navigate));
  }, [dispatch]);

  useEffect(() => {
    fetchStatesData();
  }, [fetchStatesData]);

  return (
    <>
        <FormSelectBox
          label={'State'}
          name="state_id"
          defaultValue={state_id}
          valueField={'id'}
          keyField={'name'}
          zeroOption={'Select a State'}
          onChange={onChange}
          formErrors={formErrors}
          data={stateData}
          required={required}
        />
    </>
  )
}

export default FormStatesSelectBox

